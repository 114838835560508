import {
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from 'react-router-dom';

import Favorite from "../models/favorite";
import { addCommasToNumber } from "../utils/addCommaToNumber";
import CustomSnackbar from "../utils/displaySnackbar";


const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Container = styled.div`
  flex: 1;
  margin: 5px;
  min-width: 280px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover ${Info} {
    opacity: 1;
  }
`;

const Image = styled.img`
  height: 60%;
  z-index: 2;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: absolute;
  bottom: 0;
  
  
`;

const ProductText = styled.h5`
  text-align: center;
  font-size: 17px;
  width: 200px;
`;

const ProductPrice = styled.div`
  display: flex;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  gap: 0.5rem;
`;

const OldPrice = styled.div`
  text-decoration: line-through;
  color: red;
`;

const ProductSize = styled.div`
  font-size: 14px;
  text-align: center;
  color: rgba(0,0,0,0.6);
`;

const ProductItem = ({ item }) => {
  const navigate = useNavigate();

const [isDisplaySnackBar, setIsDisplay] = React.useState(false);

  if (item.images.length > 0) {
    return (
          <Container onClick={() => navigate('/product/'+item.inventoryId)}>
        <Image src={item.images[0].image} />
        <Info>
          <Icon>
            <ShoppingCartOutlined />
          </Icon>
          <Icon>
            <SearchOutlined />
          </Icon>
          <Icon>
            <FavoriteBorderOutlined onClick={()=>{

              addToWishlist(item, setIsDisplay);

            }} />
          </Icon>
        </Info>
        <ProductTitle>
            <ProductText>
              {item.name}
            </ProductText>
            <ProductPrice> NGN {addCommasToNumber(item.sizes[0].price)} <OldPrice>  </OldPrice> </ProductPrice>
            <ProductSize> {item.sizes[0].name} </ProductSize>
        </ProductTitle>

      <CustomSnackbar open={isDisplaySnackBar} message='Added to Wishlist'/>

      </Container>
    );
  }
};

  // ..................................................
  // Add product to wishlist and save to the database
  // ..................................................
async function addToWishlist(inventory, setIsDisplay){
  // const user = getAuth(app).currentUser;

  // if(user){
  //   const db = getDatabase();

  //   let favorite = new Favorite(
  //     inventory.description,
  //     inventory.inventoryId,
  //     inventory.mainCat,
  //     inventory.name,
  //     user.uid,
  //     '',
  //     '',
  //     ''
  //   );

  //   if(inventory.images !== undefined && inventory.images.length > 0){
  //     favorite.image = inventory.images[0].image;
  //   }

  //   if(inventory.sizes !== undefined && inventory.sizes.length > 0){
  //     favorite.size = inventory.sizes[0].name;
  //     favorite.price = inventory.sizes[0].price;
  //   }

  //   set(ref(db, 'wishlist/' + user.uid + '/' + inventory.inventoryId + '/'), favorite.toJson()).catch((e) => {
  //     console.log(e)
  //   }).then((_) => {
  //     setIsDisplay(true);

  //     setTimeout(()=>{
  //       setIsDisplay(false);
  //       }, 5000);
  //   });
  // }
}

export default ProductItem;
