import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import NavBar from '../../components/nav-bar/Navbar';
import Newsletter from '../../components/Newsletter';
import { Announcement } from '../../models/announcement';
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import { Link } from "react-router-dom";
import CollectionList from './CollectionList';
import Announcements from '../../components/Announcements';

import { Collection } from '../../models/collection';
import CollectionHomeItem from './CollectionHomeItem';
import ProductSkeleton from '../../utils/productSkeleton';
import { useDispatch } from 'react-redux';
import { getAllCollectionAction } from '../../data/redux/actions/collectionAction';

const Container = styled.div`
  width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const HomeContainer = styled.div`

`;

const Title = styled.h1`
    margin: 20px;
    text-align: center;
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Filter = styled.div`
    margin: 20px;
    ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column"})};
`;

const FilterText = styled.span`
    font-size: 20px;
    font-weight: 600
    margin-right: 20px;
    ${mobile({ marginRight: "0px", fontSize: "18px"})};
`;

const Select = styled.select`
    padding: 20px;
    margin-right: 20px;
    ${mobile({ margin: "10px 0px", padding: "15px"})};
    ${tablet({ padding: "15px"})};
`;

const Option = styled.option`

`;




const CollectionHomePage = () => {
  const dispatch = useDispatch();

    const [ listOfCollections, setCollections ] = React.useState(null);



    React.useEffect(() => {
      getCollections();
  
      return () => {};
    }, []);

    const getCollections = async () => {
      if(listOfCollections !== null && listOfCollections !== undefined) return;
  
      const res = await dispatch(getAllCollectionAction());
  
      if (res.payload.data === undefined) return;
  
      const collections = res.payload.data;
  
      if(collections === undefined || collections.length < 1) return;
  
      setCollections(collections.map(col => Collection.fromJson(col)));
    }

// if(listOfCollections === null || listOfCollections === undefined){
//   setTimeout(() => {
//     getCollections().then(collectionList => {
//       if(collectionList !== null && collectionList !== undefined && collectionList.length > 0){
//         setCollections(collectionList);

//         console.log(collectionList.length);
//       }
//     });
//   }, 1000);
// }

  return (
    <HomeContainer>
      {/* <Announcements /> */}
      {/* <NavBar /> */}
      <Title> Collection </Title>
      <Container>
        {listOfCollections !== null && listOfCollections.length > 0 ? (
          listOfCollections.map((item) => (
            <CollectionHomeItem item={item} key={item.collectionId} />
          ))
        ) : (
          <Container>
            <ProductSkeleton/>
            <ProductSkeleton/>
            <ProductSkeleton/>
    </Container>
        )}
      </Container>
      {/* <Newsletter /> */}
      {/* <Footer /> */}
    </HomeContainer>
  );
}

export default CollectionHomePage