import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import {
  updateUserAction,
} from "../actions/userAction";

import { loginAction, registerAction } from "../actions/authAction";
import { AppStatus } from "../../../utils/app_status";

// Define a function to retrieve token data from local storage
const getUserFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem("persist:root");
    const parsedJson = JSON.parse(userData);
    if (
      parsedJson !== null &&
      parsedJson.user !== null &&
      parsedJson.user !== undefined
    ) {
      return JSON.parse(parsedJson.user);
    }

    return null;
  } catch (error) {
    console.error("Error loading user data from local storage:", error);
    return null;
  }
};

const getTokenFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem("persist:root");
    const parsedJson = JSON.parse(userData);
    if (
      parsedJson !== null &&
      parsedJson.user !== null &&
      parsedJson.user !== undefined
    ) {
      return JSON.parse(parsedJson.token);
    }

    return null;
  } catch (error) {
    console.error("Error loading user data from local storage:", error);
    return null;
  }
};

const getIDFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem("persist:root");
    const parsedJson = JSON.parse(userData);
    if (
      parsedJson !== null &&
      parsedJson.user !== null &&
      parsedJson.user !== undefined
    ) {
      return JSON.parse(parsedJson.id);
    }

    return null;
  } catch (error) {
    console.error("Error loading user data from local storage:", error);
    return null;
  }
};

// Create a slice to manage the states of the api call
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: getUserFromLocalStorage(),
    token: getTokenFromLocalStorage(),
    status: AppStatus.idle,
    id: getIDFromLocalStorage(),
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.id = null;
    },
  },
  //   Add a use case for each state
  extraReducers: (builder) => {
    builder

      // Use cases for loginAction
      .addCase(loginAction.pending, (state) => {
        state.status = AppStatus.loading;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.status = AppStatus.success;

        const mUser = action.payload.user;

        if (mUser && mUser.id !== undefined && mUser.id !== null) {
          state.id = mUser.id;
          mUser.id = undefined;
          mUser.password = undefined;
        }

        state.user = mUser;
        state.token = action.payload.token;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.status = AppStatus.failed;
        state.error = action.error.message;
      })

      // Use cases for register
      .addCase(registerAction.pending, (state) => {
        state.status = AppStatus.loading;
      })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.status = AppStatus.success;

        const mUser = action.payload.user;

        if (mUser && mUser.id !== undefined && mUser.id !== null) {
          state.id = mUser.id;
          mUser.id = undefined;
          mUser.password = undefined;
        }

        state.user = mUser;
        state.token = action.payload.token;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.status = AppStatus.failed;
        state.error = action.error.message;
      })

      // Use cases for user update
      .addCase(updateUserAction.pending, (state) => {
        state.status = AppStatus.loading;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.status = AppStatus.success;
        state.user = action.payload.user;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.status = AppStatus.failed;
        state.error = action.error.message;
      })

      // Clear cached data when logout
      .addCase(PURGE, (state) => {
        state.user = null;
        state.token = null;
        state.id = null;
      });
  },
});

export default authSlice;
