import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { mobile } from "../../Responsive";

import CustomSnackbar, { displaySnackbar } from "../../utils/displaySnackbar";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../data/redux/actions/userAction";
import { Customer } from "../../models/customer";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  margin: auto;
  display: block;
`;

const ModContainer = styled.div`
  position: relative;
  background: white;
  width: 40%;
  z-index: 11;
  overflow: hidden;
  padding: 20px;
  border-radius: 12px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mobile({ width: "80%" })};
`;

const Input = styled.input`
  min-width: 50%;
  flex: 1;
  background-color: white;
  border: 2px solid #4CAF50
  text-color: black;
  font-size: 18px;
  padding: 10px;
  margin: 20px 20px 0px 0px;
  ${mobile({ minWidth: "93%" })};
`;

const Label = styled.label`
  display: block;
  width: 400px;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background-color: black;
  border: none;
  width: 50%;
  color: white;
  border-radius: 5px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition-duration: 0.4s;
  &:hover {
    background-color: gray;
    color: white;
  }
  ${mobile({ fontSize: "12px", padding: "15px 10px", width: "50%" })};
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  margin-top: 25px;
  ${mobile({ fontSize: "15.5px", marginTop: "30px" })};
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  color: black;
  display: flex;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    color: gray;
  }
  ${mobile({ height: "25px", width: "25px" })};
`;

const EditUser = ({ handleClose, customer }) => {
  const dispatch = useDispatch();
  const { id, user } = useSelector((state) => state.authStore);

  const [isSavingData, setSavingData] = React.useState(false);

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const contactRef = React.useRef(null);

  if (customer !== undefined && customer !== null) {
    firstNameRef.value = customer.firstName;
    contactRef.value = customer.contact;
    lastNameRef.value = customer.lastName;
  }

  const [isDisplaySnackBar, setIsDisplay] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState("");

  const saveInformation = async (e) => {
    e.preventDefault();

    const firstName =
      firstNameRef.current.value !== ""
        ? firstNameRef.current.value
        : customer.firstName;
    const lastName =
      lastNameRef.current.value !== ""
        ? lastNameRef.current.value
        : customer.lastName;
    const contact =
      contactRef.current.value !== ""
        ? contactRef.current.value
        : customer.contact;

    const customer = Customer.fromJson(user);
    customer.firstName = firstName;
    customer.lastName = lastName;
    customer.contact = contact;

    setSavingData(true);

    const res = await dispatch(
      updateUserAction({ id: id, user: customer.toJson() })
    );

    setSavingData(false);

    if (!res.payload.success) {
      displaySnackbar("An error occured, please reload and try again", {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setSnackbarMessage,
      });
      return;
    }

    displaySnackbar("Updated account successfully", {
      setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setSnackbarMessage,
    });

    handleClose();
  };

  return (
    <Container>
      <ModContainer>
        <SocialIcon color="385999">
          <CloseIcon onClick={handleClose} />
        </SocialIcon>
        <Title> Edit Profile </Title>
        <Form>
          <Input
            ref={firstNameRef}
            id="firstname"
            type="text"
            placeholder={
              customer.firstName !== undefined
                ? customer.firstName
                : "First name"
            }
          />
          <Input
            ref={lastNameRef}
            id="lastname"
            type="text"
            placeholder={
              customer.lastName !== undefined ? customer.lastName : "Last name"
            }
          />
          <Input
            ref={contactRef}
            id="contact"
            type="number"
            placeholder={
              customer.contact !== undefined
                ? customer.contact
                : "Mobile number"
            }
          />
          <Button onClick={saveInformation}>
                {isSavingData ? (
                  <CircularProgress style={{ height: "30px", width: "30px" }} />
                ) : (
                  "Save"
                )}
              </Button>
        </Form>
      </ModContainer>

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage} />
    </Container>
  );
};

export default EditUser;
