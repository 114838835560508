import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentGateway } from "../utils/app_status";
import {
  getFlutterwavePaymentUrlAction,
  getNombaPaymentUrlAction,
  getPaystackPaymentUrlAction,
} from "../data/redux/actions/paymentAction";
import config from "../utils/config";

const Payment = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    initiatePayment();

    return () => {};
  }, []);

  const initiatePayment = async () => {
    const price = localStorage.price;
    const email = localStorage.email;
    const gateway = localStorage.gateway;

    if (price === null || price === undefined) return;
    if (email === null || email === undefined) return;
    if (gateway === null || gateway === undefined) return;

    const url = await getPaymentUrl(gateway, email, price);

    if (url == null) {
      window.location.replace(config.paymentRedirectUrl);
      return;
    }

    window.location.replace(url);

    localStorage.removeItem("url");
    localStorage.removeItem("price");
    localStorage.removeItem("email");
    localStorage.removeItem("access");
  };

  const getPaymentUrl = async (gateway, email, price) => {
    if (gateway === PaymentGateway.paystack) {
      const res = await dispatch(
        getPaystackPaymentUrlAction({
          email: email,
          price: price,
          redirectUrl: config.paymentRedirectUrl,
        })
      );

      const data = res.payload.data;

      if (!data || data.url === undefined) return null;

      return data.url;
    }

    if (gateway === PaymentGateway.flutterwave) {
      const res = await dispatch(
        getFlutterwavePaymentUrlAction({
          email: email,
          price: price,
          redirectUrl: config.paymentRedirectUrl,
        })
      );

      const data = res.payload.data;

      if (!data || data.url === undefined) return null;

      return data.url;
    }

    if (gateway === PaymentGateway.nomba) {
      const res = await dispatch(
        getNombaPaymentUrlAction({
          email: email,
          price: price,
          redirectUrl: config.paymentRedirectUrl,
        })
      );

      const data = res.payload.data;

      if (!data || data.url === undefined) return null;

      return data.url;
    }
  };

  // Render some text when redirecting
  return (
    <div style={styles.container}>
      <h3>Redirecting...</h3>
    </div>
  );
};

const styles = {
  container: {
    padding: 30,
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};

export default Payment;
