import flutterwave from "../assets/flutterwave.jpg";
import flwaveColor from "../assets/flwave-color.jpg";
import nomba from "../assets/nomba.jpg";
import nombaBlack from "../assets/nomba-black.jpg";
import paystack from "../assets/paystack.jpg";
import pstackColor from "../assets/pstack-color.jpg";

export const AppImages = {
    flutterwave: flutterwave,
    flwaveColor: flwaveColor,
    nomba: nomba,
    nombaBlack: nombaBlack,
    paystack: paystack,
    pstackColor: pstackColor,
}