import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [wishlist, setWishlist] = useState([]);

  const addToCart = (item) => {
    const foundItem = cart.find(c => c.orderNumber === item.orderNumber);
    if(foundItem === undefined){
        setCart((prevCart) => [...prevCart, item]);
    }
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter(item => item.orderNumber !== itemId));
  };

  const addToOpenOrders = (item) => {
    const foundItem = openOrders.find(c => c.orderNumber === item.orderNumber);
    if(foundItem === undefined){
        setOpenOrders((prevOpenOrders) => [...prevOpenOrders, item]);
    }
  };

  const removeFromOpenOrders = (itemId) => {
    setOpenOrders((prevOpenOrders) => prevOpenOrders.filter(item => item.orderNumber !== itemId));
  };

  const addToWishlist = (item) => {
    const foundItem = wishlist.find(c => c.inventoryId === item.inventoryId);
    if(foundItem === undefined){
        setWishlist((prevWishlist) => [...prevWishlist, item]);
    }
  };

  const removeFromWishlist = (itemId) => {
    setWishlist((prevWishlist) => prevWishlist.filter(item => item.inventoryId !== itemId));
  };

  const isFavoriteExist = (itemId) => {
    const foundItem = wishlist.find(c => c.inventoryId === itemId);
    if(foundItem === undefined){
        return false;
    }

    return true;
  }

  const clear = ({clearCart = false, clearOrder = false, clearWishlist = false}) => {
    if(clearCart) {
        setCart([]);
    }

    if(clearOrder) {
        setOpenOrders([]);
    }

    if(clearWishlist) {
        setWishlist([]);
    }
  }

  return (
    <NavbarContext.Provider value={{ 
        cart, 
        addToCart, 
        removeFromCart,
        openOrders,
        addToOpenOrders,
        removeFromOpenOrders,
        wishlist,
        addToWishlist,
        removeFromWishlist,
        isFavoriteExist,
        clear
     }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  return useContext(NavbarContext);
};
