import React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Done from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DoDisturbRoundedIcon from "@mui/icons-material/DoDisturbRounded";

import { useNavigate } from "react-router-dom";
import config from "../../utils/config";
import { ScreenSizes } from "../../utils/screen_sizes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,

  width: 400,
  [ScreenSizes.mobile]: {
    width: '85vw'
  }
};

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background-color: red;
    color: white;
  }
`;

const AllPaymentStatus = {
  checking: "checking",
  abandoned: "abandoned",
  success: "success",
  failed: "failed",
};

const CartModal = ({ showModal, paymentStatus }) => {
  const [open, setOpen] = React.useState(showModal);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let navigate = useNavigate();

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SocialIcon
            color="385999"
            onClick={() => {
              handleClose();

              if (paymentStatus === AllPaymentStatus.success) {
                setTimeout(() => {
                  window.location.replace(config.paymentRedirectUrl);
                }, 300);
              }
            }}
          >
            <CloseIcon />
          </SocialIcon>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {getModalText()}
          </Typography>
          <div style={{ textAlign: "center", flex: 1, marginTop: "20px" }}>
            {getModalIcon()}
          </div>
        </Box>
      </Modal>
    </div>
  );

  function getModalText() {
    if (paymentStatus === AllPaymentStatus.checking) {
      return "Verifying payment";
    }
    if (paymentStatus === AllPaymentStatus.abandoned) {
      return "Abandoned payment";
    }
    if (paymentStatus === AllPaymentStatus.success) {
      return "Payment Successful";
    }
    if (paymentStatus === AllPaymentStatus.failed) {
      return "Payment failed";
    }
  }

  function getModalIcon() {
    if (paymentStatus === AllPaymentStatus.checking) {
      return <CircularProgress style={{ height: "60px", width: "60px" }} />;
    }
    if (paymentStatus === AllPaymentStatus.abandoned) {
      return <DoDisturbRoundedIcon style={{ height: "60px", width: "60px" }} />;
    }
    if (paymentStatus === AllPaymentStatus.success) {
      return <Done style={{ height: "60px", width: "60px", color: "green" }} />;
    }
    if (paymentStatus === AllPaymentStatus.failed) {
      return (
        <CancelRoundedIcon
          style={{ height: "60px", width: "60px", color: "red" }}
        />
      );
    }
  }
};

export default CartModal;
