import { tablet } from "../Responsive";
import { mobile } from "../Responsive";
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
0% {
  background-position: -50rem 0;
  background-color: hsl(200, 20%, 70%);
}
100% {
  background-position: 50rem 0;
  background-color: hsl(200, 20%, 95%);
}
`;

const SkeletonLoader = styled.div`
display: flex;
padding: 2rem;
border-radius: 0.5rem;
margin-bottom: 1rem;
flex: 1;
margin: 5px;
width: 100vw;
height: 350px;
justify-content: center;
align-items: center;
position: relative;
${mobile({ height: '300px'})};

& > *:not(:last-child) {
  margin-right: 2rem;
}

.skeleton-image {
  height: 70%;
  width: 13rem;
  margin-left: 5%;
  margin-bottom: 5rem;
  background-color: #dcdcdc;
  animation: ${shimmer} 1s linear infinite alternate;
  ${tablet({ marginLeft: "10%"})};
  ${mobile({ width: "10rem", marginBottom: '0'})};
}

.skeleton-text {
  display: flex;
flex-direction: column;
z-index: 4;
position: absolute;
bottom: 0;
  justify-content: center;

  h2 {
    width: 15rem;
    height: 2rem;
    background-color: #dcdcdc;
    animation: ${shimmer} 1s linear infinite alternate;
    margin-bottom: .5rem;
  ${mobile({ width: "10rem", height: '1rem'})};
  }

  #price {
    width: 15rem;
    height: 1rem;
    background-color: #dcdcdc;
    animation: ${shimmer} 1s linear infinite alternate;
    margin-bottom: .5rem;
  ${mobile({ width: "10rem", height: '.5rem'})};
  }

  #size {
    width: 10rem;
    height: 1rem;
    background-color: #dcdcdc;
    animation: ${shimmer} 1s linear infinite alternate;
    margin-bottom: 1rem;
    margin-left: 18%;
  ${mobile({ width: "5rem", height: '.5rem', marginLeft: '25%'})};
  }
}
`;


const ProductSkeleton = () => {
    return (
        <SkeletonLoader>
        <div className="skeleton-image" />
        <div className="skeleton-text">
          <h2 />
          <p id='price'/>
          <p id='size'/>
        </div>
      </SkeletonLoader>
    );
}

export default ProductSkeleton;