import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'user-address';
const url = `${serverLink}/${endpoint}`

export const updateUserAddressAction = createAsyncThunk(endpoint+'/update', async ({id, address}) => {
    try {
        const response = await fetch(url+'/'+id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(address),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const saveUserAddressAction = createAsyncThunk(endpoint+'/save', async (address) => {
    try {
        const response = await fetch(url+'/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(address),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getOneUserAddressAction = createAsyncThunk(endpoint+'/get', async ({id, userUid}) => {
  try {
      const response = await fetch(`${url}/${userUid}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getAllAddressAction = createAsyncThunk(endpoint+'/getAll', async (userUid) => {
  try {
      const response = await fetch(url+'/'+userUid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const deleteAddressAction = createAsyncThunk(endpoint+'/delete', async ({id, userUid}) => {
    try {
        const response = await fetch(`${url}/${userUid}/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });