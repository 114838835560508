import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { mobile } from "../../Responsive";

import { Address } from "../../models/address";
import CustomSnackbar, { displaySnackbar } from "../../utils/displaySnackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUserAddressAction,
  updateUserAddressAction,
} from "../../data/redux/actions/userAddressAction";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  margin: auto;
  display: block;
`;

const ModContainer = styled.div`
  position: relative;
  background: white;
  width: 40%;
  z-index: 11;
  overflow: hidden;
  padding: 20px;
  border-radius: 12px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mobile({ width: "80%" })};
`;

const Input = styled.input`
  min-width: 50%;
  flex: 1;
  background-color: white;
  border: 2px solid #4CAF50
  text-color: black;
  font-size: 18px;
  padding: 10px;
  margin: 20px 20px 0px 0px;
  ${mobile({ minWidth: "93%" })};
`;

const Input2 = styled.input`
  min-width: 50%;
  flex: 1;
  font-size: 18px;
  padding: 10px;
  margin: 20px 20px 0px 0px;
  ${mobile({})};
`;

const Label = styled.label`
  display: block;
  width: 400px;
  ${mobile({})};
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background-color: black;
  border: none;
  width: 40%;
  color: white;
  border-radius: 5px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition-duration: 0.4s;
  &:hover {
    background-color: gray;
    color: white;
  }
  ${mobile({ fontSize: "12px", padding: "15px 10px", width: "50%" })};
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  margin-top: 25px;
  ${mobile({ fontSize: "15.5px", marginTop: "30px" })};
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  color: black;
  display: flex;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    color: gray;
  }
  ${mobile({ height: "25px", width: "25px" })};
`;

const AddressModal = ({ handleClose, address }) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const fullNameRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const addressRef = React.useRef(null);
  const stateRef = React.useRef(null);
  const cityRef = React.useRef(null);

  const [isChecked, setIsChecked] = React.useState(null);

  const [isDisplaySnackBar, setIsDisplay] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState("");

  const [isSavingData, setSavingData] = React.useState(false);

  function handleIsCheckboxChanged(e) {
    setIsChecked(e.target.checked);
  }

  React.useEffect(() => {
    updateAddressData();

    return () => {};
  }, []);

  const updateAddressData = () => {
    if (address !== undefined && address !== null) {
      fullNameRef.value = address.fullName;
      contactRef.value = address.contact;
      addressRef.value = address.streetAddress;
      stateRef.value = address.state;
      cityRef.value = address.city;

      if (isChecked == null) {
        setIsChecked(address.isDefault);
      }
    }

    if ((address === undefined || address === null) && isChecked == null) {
      setIsChecked(false);
    }
  };

  const saveAddress = async (e) => {
    e.preventDefault();

    if (id == null) return;

    const fullName =
      fullNameRef.current.value !== ""
        ? fullNameRef.current.value
        : address.fullName;
    const contact =
      contactRef.current.value !== ""
        ? contactRef.current.value
        : address.contact;
    const streetAddress =
      addressRef.current.value !== ""
        ? addressRef.current.value
        : address.streetAddress;
    const state =
      stateRef.current.value !== "" ? stateRef.current.value : address.state;
    const city =
      cityRef.current.value !== "" ? cityRef.current.value : address.city;

    const newAddress = new Address({
      city: city,
      contact: contact,
      fullName: fullName,
      isDefault: isChecked,
      state: state,
      streetAddress: streetAddress,
      userUid: id,
    });

    let res = {};

    setSavingData(true);

    if (address != null && address !== undefined) {
      const addressId = address.id;

      res = await dispatch(
        updateUserAddressAction({ id: addressId, address: newAddress })
      );
    } else {
      res = await dispatch(saveUserAddressAction(newAddress));
    }

    setSavingData(false);

    const success = res.payload.success;

    if (!success) {
      displaySnackbar("An error occured, please try again", {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setSnackbarMessage,
      });

      return;
    }

    displaySnackbar("Saved address", {
      setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setSnackbarMessage,
    });

    handleClose();
  };

  return (
    <Container>
      <ModContainer>
        <SocialIcon color="385999">
          <CloseIcon onClick={handleClose} />
        </SocialIcon>
        <Title> Add a new Address </Title>
        <Form>
          <Input
            ref={fullNameRef}
            id="fullname"
            type="text"
            placeholder={
              address !== null && address.fullName !== null
                ? address.fullName
                : "Fullname"
            }
          />
          <Input
            ref={contactRef}
            id="contact"
            type="number"
            placeholder={
              address !== null && address.contact !== null
                ? address.contact
                : "Mobile number"
            }
          />
          <Input
            ref={addressRef}
            id="address"
            type="text"
            placeholder={
              address !== null && address.streetAddress !== null
                ? address.streetAddress
                : "Delivery Address"
            }
          />
          <Input
            ref={cityRef}
            id="city"
            type="text"
            placeholder={
              address !== null && address.city !== null
                ? address.city
                : "Local government"
            }
          />
          <Input
            ref={stateRef}
            id="state"
            type="text"
            placeholder={
              address !== null && address.state !== null
                ? address.state
                : "State"
            }
          />
          <Label htmlFor="default">
            {" "}
            Set as default{" "}
            <Input2
              checked={isChecked}
              onChange={handleIsCheckboxChanged}
              type="checkbox"
            />{" "}
          </Label>
          <Button onClick={saveAddress}>
                {isSavingData ? (
                  <CircularProgress style={{ height: "30px", width: "30px" }} />
                ) : (
                  "Save"
                )}
              </Button>
        </Form>
      </ModContainer>

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage} />
    </Container>
  );
};

export default AddressModal;
