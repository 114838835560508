import React from "react";
import styled from "styled-components";

import { mobile, tablet } from "../Responsive";
import { useDispatch } from "react-redux";
import { getAllCollectionFullAction } from "../data/redux/actions/collectionAction";
import SliderComponent from "./SliderComponent";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ height: "80vh", width: "100vw" })};
  ${tablet({ height: "80vh", width: "100vw" })};
`;

const Slider = () => {
  const dispatch = useDispatch();

  const [listOfCollections, setCollections] = React.useState(null);

  React.useEffect(() => {
    getCollections();

    return () => {};
  }, []);

  const getCollections = async () => {
    if (listOfCollections !== null && listOfCollections !== undefined) return;

    const res = await dispatch(getAllCollectionFullAction());

    if (res.payload.data === undefined) return;

    const collections = res.payload.data;

    if (collections === undefined || collections.length < 1) return;

    const newCollections = [];

    collections.forEach((col) => {
      const collection = col.collection;
      const products = col.products;

      if (
        products !== undefined &&
        products.length > 0 &&
        col.collection.isASlideInCarousel
      ) {
        const images = [];

        products.forEach((inventory) => {
          if (
            inventory.images !== undefined &&
            inventory.images[0] != null &&
            inventory.images[0].image !== undefined
          ) {
            images.push(inventory.images[0].image);
          }
        });
        images.push(collection.backgroundImage);
        collection.images = images;

        newCollections.push(collection);
      }
    });

    setCollections(newCollections);
  };

  return listOfCollections !== null && listOfCollections !== undefined ? (
    <Container>
      <Wrapper>
        <SliderComponent listOfCollections={listOfCollections} />
      </Wrapper>
    </Container>
  ) : (
    <></>
  );
};

export default Slider;
