import { nanoid } from "nanoid";

class Order {
  constructor({
    orderNumber,
    inventoryId,
    inventoryImage,
    size,
    userUid,
    productName,
    status,
    quantity,
    pricePerOne,
    isLimitedEd,
    customerName,
    customerEmail,
    paymentReferenceNumber,
    dateCreated,
    lastModified = new Date,
  } = {}) {
    this.orderNumber = orderNumber || nanoid(9);
    this.inventoryId = inventoryId;
    this.userUid = userUid;
    this.productName = productName;
    this.status = status;
    this.size = size;
    this.inventoryImage = inventoryImage;
    this.quantity = quantity;
    this.pricePerOne = pricePerOne;
    this.isLimitedEd = isLimitedEd;
    this.customerName = customerName;
    this.customerEmail = customerEmail;
    this.paymentReferenceNumber = paymentReferenceNumber;
    this.dateCreated = dateCreated;
    this.lastModified = lastModified;
  }

  static fromJson(json) {
    return Object.assign(new Order(), json);
  }

  toJson() {
    return {
      orderNumber: this.orderNumber,
      inventoryId: this.inventoryId,
      userUid: this.userUid,
      productName: this.productName,
      size: this.size,
      status: this.status,
      quantity: this.quantity,
      inventoryImage: this.inventoryImage,
      pricePerOne: this.pricePerOne,
      isLimitedEd: this.isLimitedEd,
      customerName: this.customerName,
      customerEmail: this.customerEmail,
      paymentReferenceNumber: this.paymentReferenceNumber,
      dateCreated: this.dateCreated,
      lastModified: this.lastModified,
    };
  }
}

export default Order;
