import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../utils/config";

const serverLink = config.serverUrl;
const endpoint = "wishlist";
const url = `${serverLink}/${endpoint}`;

export const updateWishlistAction = createAsyncThunk(
  endpoint + "/update",
  async ({ userUid, WishlistImage }) => {
    try {
      const response = await fetch(url + "/" + userUid, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(WishlistImage),
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const saveWishlistAction = createAsyncThunk(
  endpoint + "/save",
  async (wishlist) => {
    try {
      const response = await fetch(url + "/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wishlist),
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const getOneWishlistAction = createAsyncThunk(
  endpoint + "/get",
  async ({ userUid, inventoryId }) => {
    try {
      const response = await fetch(`${url}/${userUid}/${inventoryId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const getOneWishlistFullAction = createAsyncThunk(
  endpoint + "/getOneFull",
  async ({ userUid, inventoryId }) => {
    try {
      const response = await fetch(`${url}/${userUid}/${inventoryId}/full`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const getAllWishlistByUserIdAction = createAsyncThunk(
  endpoint + "/getAllWishlist",
  async (userUid) => {
    try {
      const response = await fetch(`${url}/${userUid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const getAllWishlistByUserIdFullAction = createAsyncThunk(
  endpoint + "/getAllWishlistFull",
  async (userUid) => {
    try {
      const response = await fetch(`${url}/${userUid}/full/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);

export const deleteWishlistAction = createAsyncThunk(
  endpoint + "/delete",
  async ({ userUid, inventoryId }) => {
    try {
      const response = await fetch(`${url}/${userUid}/${inventoryId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    } catch (error) {
      return error.message;
    }
  }
);
