import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;

export const updateUserAction = createAsyncThunk('user/update', async ({id, user}) => {
    try {
        const response = await fetch(serverLink+'/user/'+id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getUserAction = createAsyncThunk('user/get', async (id) => {
  try {
      const response = await fetch(serverLink+'/user/'+id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getOneFullAction = createAsyncThunk('user/getFull', async (id) => {
  try {
      const response = await fetch(serverLink+'/user/'+id+'/full', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});
