import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'order';
const url = `${serverLink}/${endpoint}`

export const updateOrderAction = createAsyncThunk(endpoint+'/update', async ({orderNumber, order}) => {
    try {
        const response = await fetch(url+'/'+orderNumber, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const saveOrderAction = createAsyncThunk(endpoint+'/save', async (order) => {
    try {
        const response = await fetch(url+'/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getOneOrderAction = createAsyncThunk(endpoint+'/get', async ({userUid, orderNumber}) => {
  try {
      const response = await fetch(`${url}/${userUid}/${orderNumber}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getAllUserOrderAction = createAsyncThunk(endpoint+'/get', async (userUid) => {
    try {
        const response = await fetch(`${url}/${userUid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

export const deleteOrderAction = createAsyncThunk(endpoint+'/delete', async ({userUid, orderNumber}) => {
    try {
        const response = await fetch(`${url}/${userUid}/${orderNumber}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });