export class Customer {
    constructor(email, firstName, lastName, contact, id, paystackCusCode, creationDate){
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.contact = contact;
        this.id = id;
        this.creationDate = creationDate;
        this.paystackCusCode = paystackCusCode;
    }

    static fromJson(json) {
        return Object.assign(new Customer(), json);
    }

    toJson(){
        return {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            contact: this.contact,
            id: this.id,
            creationDate: this.creationDate,
            paystackCusCode: this.paystackCusCode,
        }
    }
}