import React from "react";
import styled from "styled-components";
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import { useLocation, useParams } from "react-router-dom";

import {
  Inventory,
  InventoryImage,
  InventorySize,
} from "../../models/inventory";
import CollectionList from "./CollectionList";
import { Collection } from "../../models/collection";
import { useDispatch } from "react-redux";
import { getOneCollectionFullAction } from "../../data/redux/actions/collectionAction";
import { Helmet } from "react-helmet";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  text-align: center;
`;

const Description = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;

  p {
    width: 60vw;
    padding: 20px;
    font-weight: 300;
    text-align: center;
    color: gray;
    ${mobile({ width: "80vw" })};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })};
`;

const FilterText = styled.span`
    font-size: 20px;
    font-weight: 600
    margin-right: 20px;
    ${mobile({ marginRight: "0px", fontSize: "18px" })};
`;

const Select = styled.select`
  padding: 20px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px", padding: "15px" })};
  ${tablet({ padding: "15px" })};
`;

const Option = styled.option``;

const Collections = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const location = useLocation();

  const [collectionItems, setCollectionItems] = React.useState(null);

  const [mCollectionName, setCollectionName] = React.useState(null);
  const [mCollectionImage, setCollectionImage] = React.useState(null);
  const [mCollectionDescript, setCollectionDescript] = React.useState(null);

  const [tempId, setTempId] = React.useState(null);

  React.useEffect(() => {
    if (tempId === null || tempId === undefined) {
      setTempId(id);
    }

    // .............................................
    // In a case where user switches from one collection
    // to another from the same collections page
    // (using the sidebar).
    // First empty the current list before updating
    // .............................................
    if (tempId !== undefined && tempId !== id) {
      if (collectionItems !== null)
        collectionItems.splice(0, collectionItems.length); // empty list

      getCollectionInventory();

      setTempId(id);
    }

    if (collectionItems === null || collectionItems === undefined) {
      getCollectionInventory();
    }
    return () => {};
  }, []);

  const getCollectionInventory = async () => {
    if (location.state !== null && location.state.collectionDescript !== null) {
      setCollectionName(location.state.collectionName);
      setCollectionDescript(location.state.collectionDescript);
    }

    const res = await dispatch(getOneCollectionFullAction(id));

    if (res.payload.data === undefined) return;

    const collection = Collection.fromJson(res.payload.data.collection);
    setCollectionName(collection.title);
    setCollectionDescript(collection.description);
    setCollectionImage(collection.backgroundImage);

    const products = res.payload.data.products;

    if (!products && products.length < 1) return;

    const listOfInventories = [];

    for (let product of products) {
      const inventory = Inventory.fromJson(product.product);

      const sizes = product.sizes !== undefined ? product.sizes : [];
      const images = product.images !== undefined ? product.images : [];

      inventory.sizes = sizes.map((size) => InventorySize.fromJson(size));
      inventory.images = images.map((image) => InventoryImage.fromJson(image));

      listOfInventories.push(inventory);
    }

    setCollectionItems(listOfInventories);
  };

  return (
    <Container>
      <Helmet>
        <meta property="og:title" content={mCollectionName} />
        <meta property="og:image" content={mCollectionImage} />
        <meta property="og:description" content={mCollectionDescript} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Title>
        {" "}
        {mCollectionName !== null || mCollectionName !== undefined
          ? mCollectionName
          : "Collection"}{" "}
      </Title>
      <Description>
        {" "}
        <p>
          {" "}
          {(mCollectionDescript !== null ||
            mCollectionDescript !== undefined) &&
            mCollectionDescript}{" "}
        </p>{" "}
      </Description>
      {/* <FilterContainer>
                <Filter> <FilterText> Collection type: </FilterText>
                <Select>
                    <Option>
                        Shirts
                    </Option>
                    <Option> Shorts </Option>
                    <Option> Trousers </Option>
                    <Option> T-shirts </Option>
                    <Option> Hoodies </Option>
                    <Option> Sweat-shirts </Option>
                </Select>
                <Select>
                    <Option disabled defaultValue>
                        Size
                    </Option>
                    <Option> XS </Option>
                    <Option> S </Option>
                    <Option> M </Option>
                    <Option> L </Option>
                    <Option> XL </Option>
                </Select>
                </Filter>
                <Filter> <FilterText> Sort by: </FilterText>
                    <Select>
                        <Option defaultValue> Best selling </Option>
                        <Option> Alphabetically, A-Z </Option>
                        <Option> Alphabetically, Z-A </Option>
                        <Option> Price (asc) </Option>
                        <Option> Price (desc) </Option>
                    </Select>
                </Filter>
            </FilterContainer> */}
      <CollectionList listOfInventories={collectionItems} />
      {/* <Newsletter /> */}
      {/* <Footer /> */}
    </Container>
  );
};

export default Collections;
