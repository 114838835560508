import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;

export const loginAction = createAsyncThunk('auth/login', async ({email, password}, {rejectWithValue}) => {
    try {
        const response = await fetch(serverLink+'/auth/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.message || 'Failed to login');
          }

      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const registerAction = createAsyncThunk('auth/register', async ({email, password, firstName, lastName, contact}, {rejectWithValue}) => {    
    try {
        const response = await fetch(serverLink+'/auth/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, firstName, lastName, contact }),
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.message || 'Failed to register');
          }

      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const verifyEmailAction = createAsyncThunk('auth/verifyEmail', async (email) => {    
    try {
        const response = await fetch(serverLink+'/auth/verifyEmail/'+email, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const updatePasswordAction = createAsyncThunk('password/update', async ({email, newPassword}) => {
    try {
        const response = await fetch(serverLink+'/auth/updatePassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, newPassword}),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });