import React from "react";
import styled from "styled-components";
import Navbar from "../../components/nav-bar/Navbar";
import Announcements from "../../components/Announcements";
import Footer from "../../components/Footer";
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import { Link } from "react-router-dom";

import Order from "../../models/order";
import OrderSkeleton from "../../utils/orderSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserOrderAction } from "../../data/redux/actions/orderAction";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "10px" })};
  ${tablet({ padding: "20px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
  ${mobile({ fontWeight: "200" })};
  ${tablet({ fontWeight: "200" })};
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  ${mobile({ padding: "10px" })};
`;

const TopTexts = styled.div``;

const TopText = styled.span`
text-decoration: none;
cursor: pointer;
margin 0px 10px;
&:hover {
  color: gray;
}
${tablet({ fontSize: "13px" })};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })};
`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })};
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
  padding-top: 10px;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span`
  ${mobile({ fontSize: "12px" })};
`;

const ProductId = styled.span`
  ${mobile({ fontSize: "12px" })};
`;

const ProductStatus = styled.div`
  width: 200px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  background-color: darkgray;
  color: white;
  ${mobile({ fontSize: "12px", padding: "1px", width: "100px" })};
`;

const ProductSize = styled.span`
  ${mobile({ fontSize: "12px" })};
`;

const OrderDetail = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ProductAddToCartContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })};
`;

const ProductPrice = styled.div`
  font-size: 15px;
  cursor: pointer;
  ${mobile({ marginBottom: "20px", display: "none" })};
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Button = styled.button`
padding: 10px;
border: 2px solid teal;
background-color: white;
cursor: pointer;
margin 0px 10px;
font-weight: 500;
&:hover{
    background-color: #f8f4f4;
}
`;

const PageContainer = styled.div`
  display: flex;
  height: 250px;
`;

const EmptyOrder = styled.div`
  height: 250px;

  p {
    font-weight: 300;
    text-align: center;
    color: gray;
  }
`;

const ClosedOrder = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const [orderList, setOrder] = React.useState([]);

  const [numberOfOrders, setNumberOfOrders] = React.useState(0);
  const [numberOfOpenOrders, setNumberOfOpenOrders] = React.useState(0);

  const [displayEmptyMessage, setDisplayMessage] = React.useState(false);

  React.useEffect(() => {
    getOrders();

    return () => {};
  }, []);

  const getOrders = async () => {
    if (id == null || orderList === undefined || orderList.length > 0) {
      return;
    }

    const orderRes = await dispatch(getAllUserOrderAction(id));
    const orders = orderRes.payload.data;

    if (orders.length < 1) {
      setTimeout(() => {
        setTimeout(setDisplayMessage(true), 3000);
      }, 500);
    }

    const closedOrders = orders.filter(order => order.status === 'failed' || order.status === 'abandoned');

    setTimeout(() => {
      setOrder(closedOrders);
      setNumberOfOrders(closedOrders.length);
      setNumberOfOpenOrders(orders.length - closedOrders.length);
    }, 500);

    if (closedOrders.length < 1) {
      setTimeout(() => {
        setTimeout(setDisplayMessage(true), 3000);
      }, 500);
    }
  };

  const Skeleton = () => {
    return (
      <Product>
        <PageContainer>
          <OrderSkeleton />
          <OrderDetail>
            <ProductPrice> SEE DETAILS </ProductPrice>
          </OrderDetail>
        </PageContainer>
        <Hr />
        <PageContainer>
          <OrderSkeleton />
          <OrderDetail>
            <ProductPrice> SEE DETAILS </ProductPrice>
          </OrderDetail>
        </PageContainer>
      </Product>
    );
  };

  const EmptyMessage = () => {
    return (
      <EmptyOrder>
        <p> You don't have any closed orders at the moment. </p>
      </EmptyOrder>
    );
  };

  return (
    <Container>
      <Announcements />
      {/* <Navbar /> */}
      <Wrapper>
        <Title> ORDERS </Title>
        <Top>
          <TopTexts>
            <Link
              to="/order/open"
              style={{ color: "black", textDecoration: "none" }}
            >
              <TopText> OPEN ORDERS ({numberOfOpenOrders}) </TopText>{" "}
            </Link>
            <Link
              to="/order/closed"
              style={{ color: "black", textDecoration: "none" }}
            >
              <TopText> CLOSED ORDERS ({numberOfOrders}) </TopText>
            </Link>
          </TopTexts>
        </Top>
        <Bottom>
          <Info>
            <Product>
              {orderList.length > 0 ? (
                orderList.map((order) => (
                  <PageContainer key={order.orderNumber}>
                    <ProductDetail>
                      <Image src={order.inventoryImage} />
                      <Details>
                        <ProductName>
                          {" "}
                          <b>Product:</b> {order.productName}{" "}
                        </ProductName>
                        <ProductId>
                          {" "}
                          <b>ORDER:</b> {order.orderNumber}{" "}
                        </ProductId>
                        <ProductSize>
                          {" "}
                          <b>Size:</b> {order.size}{" "}
                        </ProductSize>
                        <ProductStatus>
                          {order.status.toUpperCase()}
                        </ProductStatus>
                        <ProductSize>
                          {" "}
                          <b>Date:</b> On {order.dateTime}{" "}
                        </ProductSize>
                      </Details>
                    </ProductDetail>
                    <OrderDetail>
                      <ProductPrice> SEE DETAILS </ProductPrice>
                    </OrderDetail>
                  </PageContainer>
                ))
              ) : (
                <>
                  {displayEmptyMessage === true ? (
                    <EmptyMessage />
                  ) : (
                    <Skeleton />
                  )}
                </>
              )}
            </Product>
          </Info>
        </Bottom>
      </Wrapper>
      {/* <Footer /> */}
    </Container>
  );
};

export default ClosedOrder;
