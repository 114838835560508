export class Collection {
    constructor(title, description, collectionId, backgroundImage, isASlideInCarousel, carouselIndex, images){
        this.title = title;
        this.description = description;
        this.collectionId = collectionId;
        this.backgroundImage = backgroundImage;
        this.isASlideInCarousel = isASlideInCarousel;
        this.carouselIndex = carouselIndex;
        this.images = images;
    }

    static fromJson(json) {
        return Object.assign(new Collection(), json);
    }

    toJson(){
        return {
            title: this.title,
            description: this.description,
            collectionId: this.collectionId,
            backgroundImage: this.backgroundImage,
            isASlideInCarousel: this.isASlideInCarousel,
            carouselIndex: this.carouselIndex,
        }
    }
}

export class CollectionImage {
    constructor(imageId, imageUrl){
        this.imageId = imageId;
        this.imageUrl = imageUrl;
     }

    static fromJson(json) {
        return Object.assign(new Collection(), json);
    }

    toJson(){
        return {
            imageId: this.imageId,
            imageUrl: this.imageUrl,
        }
    }
}