import { ModeEdit } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import Announcements from "../../components/Announcements";
import Footer from "../../components/Footer";
import NavBar from "../../components/nav-bar/Navbar";
import Navbar from "../../components/nav-bar/Navbar";
import Newsletter from "../../components/Newsletter";
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import EditUser from "./EditUser";

import { useNavigate } from "react-router-dom";
import { Customer } from "../../models/customer";
import { Address } from "../../models/address";
import AccountSkeleton from "../../utils/accountSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../data/redux/actions/userAction";
import CustomSnackbar from "../../utils/displaySnackbar";
import { getAllAddressAction } from "../../data/redux/actions/userAddressAction";

const Container = styled.div``;

const Wrapper = styled.div`
  min-height: 100vh;
  margin-top: 30px;
  ${mobile({ padding: "2px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const AccountDetailsContainer = styled.div`
  padding: 2rem;
  margin: 30px 20px;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  ${mobile({ padding: "1rem", margin: "20px 10px" })};
`;

const AccountDetails = styled.h2`
  font-weight: 300;
  border-bottom: 1px solid grey;
  justify-content: space-between;
  display: flex;
  ${mobile({ fontSize: "20px" })};
`;

const Edit = styled.span`
  cursor: pointer;
  &:hover {
    color: #404040;
  }
`;

const Details = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${mobile({ paddingTop: "15px" })};

  h3 {
    font-size: 15px;
  }
`;

const AccountName = styled.span`
  font-size: 25px;
  ${mobile({ fontSize: "20px" })};
`;

const AccountEmail = styled.span`
  color: gray;
  width: 300px;
  ${mobile({ fontSize: "13px" })};
`;

const AccountNumber = styled.span`
  font-size: 15px;
  ${mobile({ fontSize: "13px" })};
`;

const AccountOverview = () => {
  const dispatch = useDispatch();
  const { user, id } = useSelector((state) => state.authStore);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [isDisplaySnackBar, setOpenSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");

  const [customer, setCustomer] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    getCustomer();
    getDefaultAddress();

    return () => {};
  }, []);

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);
  };

  const getCustomer = async () => {
    if ((id == null || user == null) && customer !== null) return;
    
    setCustomer(Customer.fromJson(user));

    const res = await dispatch(getUserAction(id));

    const data = res.payload.data;
    
    if(!data){
      displaySnackbar("An error occured, please reload the page");
      return;
    }

    setCustomer(Customer.fromJson(data));
  }

  const getDefaultAddress = async () => {
    if (id == null || defaultAddress !== null) return;

    const res = await dispatch(getAllAddressAction(id));

    const data = res.payload.data;
    
    if(!data){
      displaySnackbar("An error occured when getting address, please reload the page");
      return;
    }

    const foundDefaultAddress = data.find(add => add.isDefault == true);

    if(foundDefaultAddress === undefined){
      displaySnackbar("You don't have a default address saved");
      setDefaultAddress(new Address({city: '', contact: '', fullName: '', id: '', state: '', streetAddress: ''}));
      return;
    }

    setDefaultAddress(foundDefaultAddress);
  }

  return (
    <Container>
      {/* <Announcements /> */}
      {/* <NavBar /> */}
      <Wrapper>
        <Title> Account Overview </Title>

        {customer !== undefined && customer !== null ? (
          <AccountDetailsContainer>
            <AccountDetails>
              {" "}
              ACCOUNT DETAILS{" "}
              <Edit onClick={toggleModal}>
                {" "}
                <ModeEdit />{" "}
              </Edit>
            </AccountDetails>
            <Details>
              <AccountName>
                {" "}
                {customer.firstName} {customer.lastName}{" "}
              </AccountName>
              <AccountEmail> {customer.email} </AccountEmail>
              <AccountNumber> {customer.contact} </AccountNumber>
            </Details>
          </AccountDetailsContainer>
        ) : (
          <AccountSkeleton />
        )}

        {defaultAddress !== undefined && defaultAddress !== null ? (
          <AccountDetailsContainer>
            <AccountDetails>
              {" "}
              ADDRESS BOOK{" "}
              <Edit
                onClick={() => {
                  navigate("/addressbook");
                }}
              >
                {" "}
                <ModeEdit />{" "}
              </Edit>
            </AccountDetails>
            <Details>
              <h3>Your Default Shipping Address:</h3>
              <>
                <AccountName> {defaultAddress.fullName} </AccountName>
                <AccountEmail>
                  {" "}
                  {defaultAddress.deliveryAddress},{defaultAddress.city},{" "}
                  {defaultAddress.region}.{" "}
                </AccountEmail>
                <AccountNumber> {defaultAddress.contact} </AccountNumber>
              </>
            </Details>
          </AccountDetailsContainer>
        ) : (
          <AccountSkeleton />
        )}
        {modal && <EditUser handleClose={toggleModal} customer={customer} />}

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage} />
      </Wrapper>
      {/* <Newsletter /> */}
      {/* <Footer /> */}
    </Container>
  );
};

export default AccountOverview;
