import React, { useState } from 'react';
import styled from 'styled-components';
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";


const StyledBurger = styled.div`
width: 2rem;
height: 2rem;
top: 15px;
right: 20px;
justify-content: space-around;
flex-flow: column nowrap;
display: none;
z-index: 20;
${mobile({ display: "flex", justifyContent: "space-around", flexFlow: "column nowrap" })};
  ${tablet({ display: "flex", justifyContent: "space-around", flexFlow: "column nowrap", right: "0"})};

div{
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#ccc' : "#333"};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)' };
    }

    &:nth-child(2) {
        transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)' };
        opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)' };
    }
}

`;

const Burger = ({show, handleClose}) => {
    const [open, setOpen] = useState(show);

  return (
    <StyledBurger open={open} onClick ={() => {
      handleClose();

      setOpen(!open)}}>
        <div />
        <div />
        <div />
    </StyledBurger>
  )
}

export default Burger;
