import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'announcement';
const url = `${serverLink}/${endpoint}`


export const saveAnnouncementAction = createAsyncThunk(endpoint+'/save', async (announcement) => {
    try {
        const response = await fetch(url+'/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(announcement),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getAllAnnouncementAction = createAsyncThunk(endpoint+'/getAll', async () => {
  try {
      const response = await fetch(url+'/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const deleteAnnouncementAction = createAsyncThunk(endpoint+'/delete', async () => {
    try {
        const response = await fetch(`${url}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });