import {
  Search,
  ShoppingCartOutlined,
  FavoriteBorderOutlined,
  ShoppingBagOutlined,
  AppsRounded,
  Menu,
  Close,
} from "@mui/icons-material";
import { Badge, Drawer } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Modal from "./Modal";

import Order from "../../models/order";
import Burger from "./Burger";
import { Collection } from "../../models/collection";
import { FixedSizeList } from "react-window";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserOrderAction } from "../../data/redux/actions/orderAction";
import { getAllWishlistByUserIdAction } from "../../data/redux/actions/wishlistAction";
import { getAllCollectionAction } from "../../data/redux/actions/collectionAction";
import { PURGE } from "redux-persist";
import CustomSnackbar from "../../utils/displaySnackbar";
import { useNavbar } from "../../utils/navbarState";
import authSlice from "../../data/redux/reducers/userReducer.js";

const Container = styled.div`
  height: 60px;
  width: 100%;
  z-index: 20;
  background-color: white;
  position: sticky;
  top: 0;
  ${mobile({ height: "50px", width: "100vw", padding: "0px" })};
  ${tablet({ height: "50px", width: "100vw", padding: "0px" })};
`;

const Wrapper = styled.div`
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mobile({ padding: "10px 10px" })};
  ${tablet({ padding: "10px 10px" })};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${mobile({ flex: "0" })};
`;

const searchMob = ({ width }) => css`
  @media only screen and (max-width: 600px) {
    width: ${width / 7}%;
  }
`;

const searchTab = ({ width }) => css`
  @media only screen and (max-width: 1024px) {
    margin-right: 100px;
    width: ${width / 15}%;
  }
`;

const SearchContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  padding: 5px;
  width: ${({ width }) => width / 20 + "%"};
  ${searchTab};
  ${searchMob};
`;

const inputMob = ({ width }) => css`
  @media only screen and (max-width: 600px) {
    width: ${width / 7}%;
  }
`;

const inputTab = ({ width }) => css`
  @media only screen and (max-width: 1024px) {
    width: ${width / 6}%;
  }
`;

const inputLargerTab = ({ width }) => css`
  @media only screen and (min-width: 1025px) and (max-width: 1290px) {
    width: ${width / 8}%;
  }
`;

const Input = styled.input`
  border: none;
  width: ${({ width }) => width / 6 + "%"};
  ${inputMob};
  ${inputLargerTab};
  ${inputTab};
`;

const Logo = styled.h1`
  font-weight: bold;
  ${mobile({ fontSize: "24px" })};
`;

const Center = styled.div`
  flex: 2;
  text-align: center;
`;

const Right = styled.div`
  display: ${({ isLoggedIn }) => (isLoggedIn ? "flex" : "none")};
  flex-flow: row nowrap;
  margin-right: 20px;
  ${mobile({ width: "200px", display: "none" })};
  ${tablet({ display: "none" })};
`;

const tab = ({ showBurgerContainer, isLoggedIn }) => css`
  @media only screen and (max-width: 1024px) {
    display: ${({ showBurgerContainer }) =>
      showBurgerContainer ? "flex" : "none"};
    flex-flow: column nowrap;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 1;
    width: 300px;
    padding: 3rem 10px;
    gap: 0;
    margin-right: auto;
  }
`;

const BurgerContainer = styled.div`
  display: none;
  ${tab}
`;

const SidebarItemText = styled.span`
  font-size: 24px;
  text-align: center;
  margin-bottom: 50px;
`;

const BurgerCollectionContainer = styled.div`
  height: 80vh;
`;

const BurgerCollectionItems = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const BurgerCollectionItemText = styled.span`
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  &:hover {
    color: grey;
  }
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const BurgerMenuContainer = styled.div`
  margin-bottom: 100px;
`;

const BurgerMenus = styled.div`
  flex-flow: row nowrap;
  margin-right: 30px;
  margin-bottom: 10px;
  height: 5vh;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const BurgerMenuItem = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  &:hover {
    color: grey;
  }
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  &:hover {
    color: grey;
  }
  ${mobile({ fontSize: "12px", marginLeft: "10px" })};
  ${tablet({
    fontSize: "12px",
    width: "100%",
    display: "flex",
    height: "4rem",
    alignItems: "center",
    color: "black",
  })};
`;

const BurgerStyle = styled.div`
  display: flex;
  align: center;
  position: absolute;
  right: 0;
  flex-flow: row nowrap;
  margin-right: 20px;
`;

const NavBar = ({handleSearchFieldSubmit, query}) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const { cart, addToCart, openOrders, addToOpenOrders, wishlist, addToWishlist, clear } = useNavbar();

  const [showAccountSetupModal, setShowAccountSetupModal] =
    React.useState(false);

  const [showBurgerMenuData, setMenuDisplay] = React.useState(false);

  const [processSignIn, setProcessSignIn] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  const [listOfCollections, setCollections] = React.useState(null);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    getOrders();
    getFavorites();
    getCollections();

    const loginTimeout = setTimeout(()=>{
      if(id != null && isLoggedIn == null){
        setIsLoggedIn(true);
      }
    }, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(loginTimeout);
    };
  }, []);

  let navigate = useNavigate();
  let location = useLocation();

  const searchTextData = React.useRef(null);

  const [isDisplaySnackBar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);
  };

  const getOrders = async () => {
    if (id == null || id === undefined) {
      return;
    }

    const orderRes = await dispatch(getAllUserOrderAction(id));
    const orders = orderRes.payload.data;

    if(orders == null || orders === undefined || orders.length === undefined) return;
   
    orders.forEach((order) => {
      let foundOrder = Order.fromJson(order);

      if (
        (foundOrder.paymentReferenceNumber === undefined ||
          foundOrder.paymentReferenceNumber === "") &&
        (foundOrder.status === "success" || foundOrder.status === "pending")
      ) {
        addToCart(foundOrder);
      }

      if (foundOrder.status !== "failed") {
        addToOpenOrders(foundOrder);
      }
    });
  };

  const getFavorites = async () => {
    if (id == null || id === undefined) {
      return;
    }

    const favoriteRes = await dispatch(getAllWishlistByUserIdAction(id));
    const favorites = favoriteRes.payload.data;

    if(favorites == null || favorites === undefined || favorites.length === undefined) return;

    favorites.forEach(fav => addToWishlist(fav));
  };

  const getCollections = async () => {
    const collectionRes = await dispatch(getAllCollectionAction());
    const collections = collectionRes.payload.data;

    if(collections === undefined || collections.length < 1) return;

    setCollections(collections.map(collection => Collection.fromJson(collection)));
  };

  const handleSearchFieldClick = () => {
    // e.preventDefault();

    let currentUrl = location.pathname;

    if (currentUrl.includes("search")) return;

    navigate("/search");

    // todo: fix this to focus textfield onPageLoad

    // setTimeout(()=>{
    //   searchTextData.current.focus();
    // }, 1000);
  };

  const isUserLoggedIn = () => {
    window.location.reload();
  }

  function logout() {
    setIsLoggedIn(false);
    clear({clearCart: true, clearOrder: true, clearWishlist: true});
    try{
      dispatch(authSlice.actions.clearUser());
      dispatch({ type: PURGE });
    } catch (e){}
  }

  const showAccountModal = (isSignIn) => {
    setShowAccountSetupModal(true);
    setProcessSignIn(isSignIn);
  };

  const hideAccountModal = () => {
    setShowAccountSetupModal(false);
  };

  const toggleBurgerMenu = () => {
    setMenuDisplay(!showBurgerMenuData);
  };

  const LoggedInNavIcons = () => {
    return (
      <>
        <MenuItem onClick={logout}> LOGOUT </MenuItem>
        <MenuItem onClick={() => navigate("/profile")}> MY ACCOUNT </MenuItem>
        <MenuItem>
          <AppsRounded onClick={() => navigate("/collections")} />
        </MenuItem>

        <MenuItem>
          <Badge
            badgeContent={cart.length}
            color="primary"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <ShoppingCartOutlined />
          </Badge>
        </MenuItem>

        <MenuItem>
          <Badge
            badgeContent={wishlist.length}
            color="primary"
            onClick={() => {
              navigate("/wishlist");
            }}
          >
            <FavoriteBorderOutlined />
          </Badge>
        </MenuItem>
        <MenuItem>
          <Badge
            badgeContent={openOrders.length}
            color="primary"
            onClick={() => {
              navigate("/order/open");
            }}
          >
            <ShoppingBagOutlined />
          </Badge>
        </MenuItem>
      </>
    );
  };

  const LoggedOutNavIcons = () => {
    return (
      <>
        <MenuItem
          onClick={() => {
            showAccountModal(false);
          }}
        >
          {" "}
          SIGN UP{" "}
        </MenuItem>
        <MenuItem
          onClick={() => {
            showAccountModal(true);
          }}
        >
          {" "}
          SIGN IN{" "}
        </MenuItem>
        <MenuItem>
          <Badge
            badgeContent={0}
            color="primary"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <ShoppingCartOutlined />
          </Badge>
        </MenuItem>

        <MenuItem>
          <AppsRounded onClick={() => navigate("/collections")} />
        </MenuItem>

        <MenuItem>
          <Badge
            badgeContent={0}
            color="primary"
            onClick={() => {
              navigate("/wishlist");
            }}
          >
            <FavoriteBorderOutlined />
          </Badge>
        </MenuItem>
        <MenuItem>
          <Badge
            badgeContent={0}
            color="primary"
            onClick={() => {
              navigate("/order/open");
            }}
          >
            <ShoppingBagOutlined />
          </Badge>
        </MenuItem>
      </>
    );
  };

  const BurgerRow = ({ index, style }) => {
    return (
      <BurgerCollectionItemText
        style={style}
        key={listOfCollections[index].collectionId}
        onClick={() => {
          toggleBurgerMenu();

          navigate("/collection/" + listOfCollections[index].collectionId, {
            state: {
              collectionName: listOfCollections[index].title,
              collectionDescript: listOfCollections[index].description,
            },
          });
        }}
      >
        {listOfCollections[index].title}
      </BurgerCollectionItemText>
    );
  };

  const BurgerDrawerComponent = () => {
    let height = screenHeight * 0.6;

    if (screenHeight <= 730) {
      height = screenHeight * 0.5;
    }

    if (screenHeight <= 530) {
      height = screenHeight * 0.4;
    }

    return (
      <>
        <SidebarItemText>Collections</SidebarItemText>

        {listOfCollections !== null && listOfCollections.length > 0 ? (
          <BurgerCollectionContainer>
            <BurgerCollectionItems>
              <FixedSizeList
                height={height}
                width={300}
                itemSize={50}
                itemCount={listOfCollections.length}
              >
                {BurgerRow}
              </FixedSizeList>
            </BurgerCollectionItems>
          </BurgerCollectionContainer>
        ) : (
          <BurgerCollectionContainer />
        )}

        <Hr />

        <BurgerMenuContainer>
          <BurgerMenus>
            <BurgerMenuItem show={true}>
              <AppsRounded onClick={() => navigate("/collections")} />
            </BurgerMenuItem>

            <BurgerMenuItem show={true}>
              <Badge
                badgeContent={cart.length}
                color="primary"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                <ShoppingCartOutlined />
              </Badge>
            </BurgerMenuItem>

            <BurgerMenuItem show={true}>
              <Badge
                badgeContent={wishlist.length}
                color="primary"
                onClick={() => {
                  navigate("/wishlist");
                }}
              >
                <FavoriteBorderOutlined />
              </Badge>
            </BurgerMenuItem>

            <BurgerMenuItem show={true}>
              <Badge
                badgeContent={openOrders.length}
                color="primary"
                onClick={() => {
                  navigate("/order/open");
                }}
              >
                <ShoppingBagOutlined />
              </Badge>
            </BurgerMenuItem>
          </BurgerMenus>

          <BurgerMenus>
            <BurgerMenuItem
              show={!isLoggedIn}
              onClick={() => {
                showAccountModal(false);
              }}
            >
              {" "}
              SIGN UP{" "}
            </BurgerMenuItem>

            <BurgerMenuItem
              show={!isLoggedIn}
              onClick={() => {
                showAccountModal(true);
              }}
            >
              {" "}
              SIGN IN{" "}
            </BurgerMenuItem>

            <BurgerMenuItem
              show={isLoggedIn}
              onClick={() => {
                toggleBurgerMenu();

                navigate("/profile");
              }}
            >
              {" "}
              MY ACCOUNT{" "}
            </BurgerMenuItem>

            <BurgerMenuItem show={isLoggedIn} onClick={logout}>
              {" "}
              LOGOUT{" "}
            </BurgerMenuItem>
          </BurgerMenus>
        </BurgerMenuContainer>
      </>
    );
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            <Logo>Byagoro</Logo>
          </Link>
        </Left>

        <Center>
          <SearchContainer width={screenWidth}>
            <form onSubmit={handleSearchFieldSubmit}>
              <Input
                placeholder="Search"
                ref={query ? query : searchTextData}
                type="text"
                onClick={handleSearchFieldClick}
                width={screenWidth}
              />
            </form>
            <Search
              style={{ color: "gray", fontsize: 16 }}
              onClick={() => {
                // searchTextData.current.focus();
              }}
            />
          </SearchContainer>
        </Center>

        <Right
          isLoggedIn={isLoggedIn}
          showBurgerContainer={!showBurgerMenuData}
        >
          <LoggedInNavIcons />
        </Right>

        <Right
          isLoggedIn={!isLoggedIn}
          showBurgerContainer={!showBurgerMenuData}
        >
          <LoggedOutNavIcons />
        </Right>

        <BurgerContainer showBurgerContainer={showBurgerMenuData}>
          <BurgerDrawerComponent />
        </BurgerContainer>

        <BurgerStyle>
          <Burger show={showBurgerMenuData} handleClose={toggleBurgerMenu} />
        </BurgerStyle>

        <Modal
          show={showAccountSetupModal}
          isSignIn={processSignIn}
          handleClose={hideAccountModal}
          isUserLoggedIn={isUserLoggedIn}
          displaySnackbar={displaySnackbar}
        />

      <CustomSnackbar open={isDisplaySnackBar} message={snackbarMessage} />

      </Wrapper>
    </Container>
  );
};

export default NavBar;
