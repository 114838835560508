import React from "react";
import styled from "styled-components";
import Navbar from "../components/nav-bar/Navbar";
import Announcements from "../components/Announcements";
import Footer from "../components/Footer";
import { tablet } from "../Responsive";
import { Link, useNavigate } from "react-router-dom";

import Favorite from "../models/favorite";
import Order from "../models/order";
import OrderSkeleton from "../utils/orderSkeleton";
import { addCommasToNumber } from "../utils/addCommaToNumber";
import { AddShoppingCart, FavoriteBorderOutlined } from "@mui/icons-material";
import CustomSnackbar, { displaySnackbar } from "../utils/displaySnackbar";
import { useDispatch, useSelector } from "react-redux";
import { deleteWishlistAction, getAllWishlistByUserIdAction, getAllWishlistByUserIdFullAction } from "../data/redux/actions/wishlistAction";
import MyFavorite from "../models/favorite";
import { getAllUserOrderAction, saveOrderAction } from "../data/redux/actions/orderAction";
import { useNavbar } from "../utils/navbarState";

const Container = styled.div``;
const Wrapper = styled.div`
  padding: 20px;
  ${tablet({ padding: "10px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  ${tablet({ display: "none" })};
`;

const TopText = styled.span`
text-decoration: underline;
cursor: pointer;
margin 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${tablet({ flexDirection: "column" })};
`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  justify-content: space-between;
  ${tablet({ flexDirection: "column" })};
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
  height: 250px;
  object-fit: cover;
  padding-top: 10px;
  ${tablet({ height: "200px" })};
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${tablet({ marginTop: "20px" })};
`;

const ProductAddToCartContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${tablet({ marginBottom: "20px" })};
`;

const Button = styled.button`
padding: 10px;
border: 2px solid black;
background-color: white;
cursor: pointer;
margin 0px 10px;
font-weight: 500;
&:hover{
    background-color: #f8f4f4;
}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${tablet({ flexDirection: "column" })};
`;

const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`;

const EmptyCircle = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #edeade;
`;

const EmptyIcon = styled.span`
  .emptyIcon {
    font-size: 50px;
  }
`;

const EmptyCart = styled.p`
  font-weight: 300;
  text-align: center;
  color: gray;
`;

const EmptyButton = styled.button`
  padding: 10px;
  background-color: black;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
`;

const WishList = () => {
  const dispatch = useDispatch();
  const { user, id } = useSelector((state) => state.authStore);

  const navbarState = useNavbar();

  const [wishlist, setWishlist] = React.useState([]);

  const [orderList, setOrders] = React.useState([]);

  const [displayEmptyMessage, setDisplayMessage] = React.useState(false);

  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [isDisplaySnackBar, setIsDisplay] = React.useState(false);

  const navigate = useNavigate();


  React.useEffect(() => {
    getOrders();
    getWishlist();

    return () => {};
  }, []);

  const removeFromWishlist = async (favItem) => {
    if (id == null) {
      displaySnackbar("Please sign in to remove item from your wishlist",
      {setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setSnackBarMessage,});
      return;
    }

    const res = await dispatch(
      deleteWishlistAction({ inventoryId: favItem.inventoryId, userUid: id })
    );

    const success = res.payload.success;

    if (!success) {
      displaySnackbar(res.payload.message,
        {setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setSnackBarMessage,});
      return;
    }

    navbarState.removeFromWishlist(favItem.inventoryId);
    setWishlist(list => list.filter(item => item.inventoryId !== favItem.inventoryId));

    displaySnackbar("Removed item from your wishlist",
    {setOpenSnackbar: setIsDisplay,
    setSnackbarMessage: setSnackBarMessage,});
  };

  const addToCart = async (favorite) => {
    if (id == null) {
      displaySnackbar("Please sign in to add item to your cart", {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setIsDisplay,
      });
      return;
    }

    if (favorite.name === undefined) {
      displaySnackbar("An error occured, please reload the page and try again", {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setIsDisplay,
      });
      return;
    }

    const order = new Order({
      customerEmail: user.email,
      customerName: user.displayName !== null && user.displayName !== undefined ? user.displayName : user.email,
      inventoryId: favorite.inventoryId,
      inventoryImage: favorite.image,
      isLimitedEd: false,
      pricePerOne: favorite.price,
      productName: favorite.name,
      quantity:1,
      size: favorite.size,
      status: 'pending',
      userUid: id,
      paymentReferenceNumber: ''
    });

    const res = await dispatch(saveOrderAction(order.toJson()));

    const success = res.payload.success;

    if (!success) {
      displaySnackbar(res.payload.messge, {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setIsDisplay,
      });
      return;
    }

    navbarState.addToCart(order);
    navbarState.addToOpenOrders(order);

    displaySnackbar("Added item to cart", {
      setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setIsDisplay,
    });
  };

  const getWishlist = async () => {
    if (id == null || wishlist.length > 0) {
      return;
    }

    const favoriteRes = await dispatch(getAllWishlistByUserIdFullAction(id));
    const favorites = favoriteRes.payload.data;

    if(favorites === undefined || favorites.length < 1) {
      setTimeout(setDisplayMessage(true), 3000);
      return;
    }
    
    const newFavorites = [];

    for(let favorite of favorites){
      const inventory = favorite.product;
      const sizes = favorite.sizes;
      const images = favorite.images;

      const newFavorite = new MyFavorite();
      newFavorite.description = inventory.description;
      newFavorite.image = images !== undefined && images.length > 0 ? images[0].image : '';
      newFavorite.inventoryId = inventory.inventoryId;
      newFavorite.name = inventory.name;
      newFavorite.size = sizes !== undefined && sizes.length > 0 ? sizes[0].name : '';
      newFavorite.userUid = id;
      newFavorite.price = sizes !== undefined && sizes.length > 0 ? sizes[0].price : '';

      newFavorites.push(newFavorite);
    }

    setWishlist(newFavorites);
  };

  const getOrders = async () => {
    if (id == null || orderList.length > 0) {
      return;
    }

    const orderRes = await dispatch(getAllUserOrderAction(id));
    const orders = orderRes.payload.data;

    if(!orders || orders.length < 1) return;

    const openOrders = orders.filter(order => order.status === 'pending' && (order.status !== 'failed' || order.status !== 'abandoned'));

    setOrders(openOrders);
  };

  const Skeleton = () => {
    return (
      <Product>
        <PageContainer>
          <OrderSkeleton />
          <PriceDetail>
            <ProductAddToCartContainer>
              <Button> ADD TO CART </Button>
              <Button> REMOVE </Button>
            </ProductAddToCartContainer>
            <ProductPrice> NGN 0.0 </ProductPrice>
          </PriceDetail>
        </PageContainer>
        <Hr />
        <PageContainer>
          <OrderSkeleton />
          <PriceDetail>
            <ProductAddToCartContainer>
              <Button> ADD TO CART </Button>
              <Button> REMOVE </Button>
            </ProductAddToCartContainer>
            <ProductPrice> NGN 0.0 </ProductPrice>
          </PriceDetail>
        </PageContainer>
      </Product>
    );
  };

  const EmptyMessage = () => {
    return (
      <EmptyContainer>
        <EmptyCircle>
          <EmptyIcon>
            {" "}
            <FavoriteBorderOutlined className="emptyIcon" />{" "}
          </EmptyIcon>
        </EmptyCircle>
        <EmptyCart>your wishlist is empty!</EmptyCart>
        <EmptyButton> Start Shopping </EmptyButton>
      </EmptyContainer>
    );
  };

  return (
    <Container>
      <Announcements />
      {/* <Navbar /> */}
      <Wrapper>
        <Title> WISHLIST </Title>
        <Top>
          <TopButton onClick={() => {navigate('/collections')}}> CONTINUE SHOPPING </TopButton>
          <TopTexts>
              <TopText onClick={() => {navigate('/cart')}}> Shopping Bag ({orderList.length}) </TopText>

            <TopText> Your Wishlist ({wishlist.length}) </TopText>
          </TopTexts>
        </Top>

        <Bottom>
          <Info>
            <Product>
              {wishlist.length > 0 ? (
                wishlist.map((favorite, index) => (
                  <React.Fragment key={favorite.inventoryId}>
                    <PageContainer>
                      <ProductDetail>
                        <Image src={favorite.image} />
                        <Details>
                          <ProductName>
                            {" "}
                            <b>Product:</b>
                            {favorite.name}{" "}
                          </ProductName>
                          <ProductId>
                            {" "}
                            <b>ID:</b> {favorite.inventoryId}{" "}
                          </ProductId>
                          <ProductColor color="black" />
                          <ProductSize>
                            {" "}
                            <b>Size:</b> {favorite.size}{" "}
                          </ProductSize>
                        </Details>
                      </ProductDetail>
                      <PriceDetail>
                        <ProductAddToCartContainer>
                          <Button onClick={() => addToCart(favorite)}>
                            {" "}
                            ADD TO CART{" "}
                          </Button>
                          <Button onClick={() => removeFromWishlist(favorite)}>
                            {" "}
                            REMOVE{" "}
                          </Button>
                        </ProductAddToCartContainer>
                        <ProductPrice>
                          {" "}
                          NGN {addCommasToNumber(favorite.price)}{" "}
                        </ProductPrice>
                      </PriceDetail>
                    </PageContainer>
                    {index < wishlist.length - 1 && <Hr />}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {displayEmptyMessage || navbarState.wishlist.length < 1 ? (
                    <EmptyMessage />
                  ) : (
                    <Skeleton />
                  )}
                </>
              )}
            </Product>
          </Info>
        </Bottom>
      </Wrapper>

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage}/>

      {/* <Footer /> */}
    </Container>
  );
};

export default WishList;
