import React, { useState } from "react";

import styled from "styled-components";
import Navbar from "../../components/nav-bar/Navbar";
import Announcements from "../../components/Announcements";
import Footer from "../../components/Footer";
import { Add, AddShoppingCart, Remove } from "@mui/icons-material";
import { tablet } from "../../Responsive";
import { Link } from "react-router-dom";

import Order from "../../models/order";
import CartModal from "./CartModal";
import { addCommasToNumber } from "../../utils/addCommaToNumber";
import OrderSkeleton from "../../utils/orderSkeleton";
import { Icon } from "@mui/material";
import { getAllUserOrderAction } from "../../data/redux/actions/orderAction";
import { getAllWishlistByUserIdAction } from "../../data/redux/actions/wishlistAction";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../utils/images";
import { PaymentGateway } from "../../utils/app_status";
import { useParams } from "react-router-dom";
import { verifyFlutterwavePaymentAction, verifyNombaPaymentAction, verifyPaystackPaymentAction } from "../../data/redux/actions/paymentAction";
import { useLocation } from "react-router-dom/dist";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  ${tablet({ padding: "10px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  ${tablet({ display: "none" })};
`;

const TopText = styled.span`
text-decoration: underline;
cursor: pointer;
margin 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${tablet({ flexDirection: "column" })};
`;

const Info = styled.div`
  flex: 3;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${tablet({ flexDirection: "column" })};
`;

const Product = styled.div`
  justify-content: space-between;
  ${tablet({ flexDirection: "column" })};
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
  height: 250px;
  padding-top: 10px;
  ${tablet({ height: "200px" })};
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${tablet({ margin: "5px 15px" })};
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${tablet({ marginBottom: "20px" })};
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  height: 50vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  max-width: 25vw;
  padding: 3px 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  
  @media only screen and (max-width: 600px) {
    max-width: 100vw;
  }
`;

const ButtonLogo = styled.img`
  display: flex;
  width: 30%;
  max-width: 70px;
  object-fit: cover;
  height: 40px;
  margin-right: 10px;
`;

const ButtonText = styled.span`
  display: flex;
  color: white;
  font-size: 14px;
`;

const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`;

const EmptyCircle = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #edeade;
`;

const EmptyIcon = styled.span`
  .emptyIcon {
    font-size: 50px;
  }
`;

const EmptyCart = styled.p`
  font-weight: 300;
  text-align: center;
  color: gray;
`;

const EmptyButton = styled.button`
  padding: 10px;
  background-color: black;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
`;

const Cart = () => {
  const dispatch = useDispatch();
  const { id, user } = useSelector((state) => state.authStore);

  const [orderList, setOrder] = useState([]);

  const [wishListSize, setWishlistSize] = useState(null);

  const [totalPrice, setPrice] = useState("0.0");
  const [totalPriceWDelivery, setPriceWDelivery] = useState("0.0");

  const [showPaymentCheckModal, setModal] = useState(false);

  const [displayEmptyMessage, setDisplayMessage] = useState(false);

  const [paymentStatus, setStatus] = React.useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  React.useEffect(() => {
    getOrders();
    getFavorites();

    verifyPayment();

    return () => {};
  }, []);

  const verifyPayment = async () => {
    const gateway = localStorage.gateway;
    // if(gateway === undefined) return;

    let reference = searchParams.get("reference"); // paystack
    if(reference === undefined || reference == null){
      reference = searchParams.get("transaction_id"); // flutterwave
    }

    if(reference == null) return;

    // if(reference === undefined && gateway !== undefined){
    //   setStatus('failed');
    //   setModal(true);
    //   return;
    // }

    setModal(true);
    setStatus('checking');
    
    const status = await verifyPay(gateway, reference);
    setStatus(status);
    localStorage.removeItem('gateway');
  }

  const verifyPay = async (gateway, reference) => {

    if(gateway === undefined) return checkAllGateways(reference);

    if(gateway == PaymentGateway.paystack){
      const res = await dispatch(verifyPaystackPaymentAction({reference: reference, userUid: id}));

      if(!res || res.payload.data === undefined){
        return null;
      }
  
      return res.payload.data;
    }
    if(gateway == PaymentGateway.flutterwave){
      const res = await dispatch(verifyFlutterwavePaymentAction({reference: reference, userUid: id}));

      if(!res || res.payload.data === undefined){
        return null;
      }
  
      return res.payload.data;
    }
    if(gateway == PaymentGateway.paystack){
      const res = await dispatch(verifyNombaPaymentAction({reference: reference, userUid: id}));

      if(!res || res.payload.data === undefined){
        return null;
      }
  
      return res.payload.data;
    }
  }

  const checkAllGateways = async (reference) => {
    let res = await dispatch(verifyPaystackPaymentAction({reference: reference, userUid: id}));

    if(res.payload.data != undefined && res.payload.data === 'success'){
      return res.payload.data;
    }

    res = await dispatch(verifyFlutterwavePaymentAction({reference: reference, userUid: id}));
    if(res.payload.data != undefined && res.payload.data === 'success'){
      return res.payload.data;
    }

    res = await dispatch(verifyNombaPaymentAction({reference: reference, userUid: id}));
    if(res.payload.data != undefined && res.payload.data === 'success'){
      return res.payload.data;
    }
    
    return 'failed';
  }

  const getOrders = async () => {
    if (id == null || orderList === undefined || orderList.length > 0) {
      return;
    }

    const orderRes = await dispatch(getAllUserOrderAction(id));
    const orders = orderRes.payload.data;

    if (!orders || orders.length < 1) {
      setTimeout(() => {
        setTimeout(setDisplayMessage(true), 3000);
      }, 500);

      return;
    }

    const openOrders = orders.filter(order => order.status === 'pending' && (order.status !== 'failed' || order.status !== 'abandoned'));

    if (!openOrders || openOrders.length < 1) {
      setTimeout(() => {
        setTimeout(setDisplayMessage(true), 3000);
      }, 500);

      return;
    }

    setTimeout(() => {
      setOrder(openOrders);

      getTotalPrice(openOrders);
    }, 500);
  };

  const getFavorites = async () => {
    if (id == null || id === undefined || wishListSize != null) {
      return;
    }

    const favoriteRes = await dispatch(getAllWishlistByUserIdAction(id));
    const favorites = favoriteRes.payload.data;

    if (favorites === undefined || favorites.length < 1) {
      setTimeout(() => {
        setWishlistSize(0);
      }, 500);

      return;
    }

    setTimeout(() => {
      setWishlistSize(favorites.length);
    }, 500);
  };

  const getTotalPrice = (orders) => {
    let price = 0.0;

    orders.forEach((order) => {
      price = parseInt(order.pricePerOne) * order.quantity + parseInt(price);
    });

    setPriceWDelivery(parseInt(price) + 200);

    setPrice(price);
  };

  const Skeleton = () => {
    return (
      <Product>
        <PageContainer>
          <OrderSkeleton />
          <PriceDetail>
            <ProductAmountContainer>
              <Add />
              <ProductAmount> 0 </ProductAmount>
              <Remove />
            </ProductAmountContainer>
            <ProductPrice>NGN 0.0</ProductPrice>
          </PriceDetail>
        </PageContainer>
        <Hr />
        <PageContainer>
          <OrderSkeleton />
          <PriceDetail>
            <ProductAmountContainer>
              <Add />
              <ProductAmount> 0 </ProductAmount>
              <Remove />
            </ProductAmountContainer>
            <ProductPrice>NGN 0.0</ProductPrice>
          </PriceDetail>
        </PageContainer>
      </Product>
    );
  };

  const EmptyMessage = () => {
    return (
      <EmptyContainer>
        <EmptyCircle>
          <EmptyIcon>
            {" "}
            <AddShoppingCart className="emptyIcon" />{" "}
          </EmptyIcon>
        </EmptyCircle>
        <EmptyCart>your cart is empty!</EmptyCart>
        <EmptyButton> Start Shopping </EmptyButton>
      </EmptyContainer>
    );
  };

  const CheckoutButton = ({ logoSrc, text, gateway }) => {
    return (
      <Button onClick={() => localStorage.setItem('gateway', gateway)}>
        <ButtonLogo src={logoSrc} alt="logo" />
        <ButtonText>{text}</ButtonText>
      </Button>
    );
  };

  return (
    <Container>
      <Announcements />
      {/* <Navbar /> */}
      <Wrapper>
        <Title> CART </Title>
        <Top>
          <Link to="/collections" style={{ color: "white" }}>
            <TopButton> CONTINUE SHOPPING </TopButton>
          </Link>
          <TopTexts>
            <TopText> Shopping Bag ({orderList.length}) </TopText>
            <Link to="/wishlist" style={{ color: "black" }}>
              <TopText> Your Wishlist ({wishListSize}) </TopText>
            </Link>
          </TopTexts>
          <Link
            to="/payment"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              localStorage.setItem(
                "price",
                parseInt(totalPriceWDelivery) * 100
              );
              // localStorage.setItem("email", getAuth(app).currentUser.email);
            }}
          >
            <TopButton type="filled">CHECKOUT NOW</TopButton>
          </Link>
        </Top>

        <Bottom>
          <Info>
            <Product>
              {orderList.length > 0 ? (
                orderList.map((order, index) => (
                  <React.Fragment key={order.orderNumber}>
                    <PageContainer>
                      <ProductDetail>
                        <Image src={order.inventoryImage} />
                        <Details>
                          <ProductName>
                            {" "}
                            <b>Product:</b>
                            {order.productName}{" "}
                          </ProductName>
                          <ProductId>
                            {" "}
                            <b>ID:</b> {order.orderNumber}{" "}
                          </ProductId>
                          <ProductColor color="black" />
                          <ProductSize>
                            {" "}
                            <b>Size:</b> {order.size}{" "}
                          </ProductSize>
                        </Details>
                      </ProductDetail>
                      <PriceDetail>
                        <ProductAmountContainer>
                          <Add />
                          <ProductAmount> {order.quantity} </ProductAmount>
                          <Remove />
                        </ProductAmountContainer>
                        <ProductPrice>
                          {" "}
                          NGN{" "}
                          {addCommasToNumber(
                            order.quantity * order.pricePerOne
                          )}{" "}
                        </ProductPrice>
                      </PriceDetail>
                    </PageContainer>

                    {index < orderList.length - 1 && <Hr />}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {displayEmptyMessage === true ? (
                    <EmptyMessage />
                  ) : (
                    <Skeleton />
                  )}
                </>
              )}
            </Product>
          </Info>

          {showPaymentCheckModal ? (
            <div>
              <CartModal
                showModal={showPaymentCheckModal}
                paymentStatus={paymentStatus}
              />
            </div>
          ) : (
            <></>
          )}

          <Summary>
            <SummaryTitle> ORDER SUMMARY </SummaryTitle>
            <SummaryItem>
              <SummaryItemText> Subtotal </SummaryItemText>
              <SummaryItemPrice>
                {" "}
                NGN {addCommasToNumber(totalPrice)}{" "}
              </SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText> Estimated Shipping </SummaryItemText>
              <SummaryItemPrice> NGN 200 </SummaryItemPrice>
            </SummaryItem>
            <SummaryItem type="total">
              <SummaryItemText> Total </SummaryItemText>
              <SummaryItemPrice>
                {" "}
                NGN {addCommasToNumber(totalPriceWDelivery)}{" "}
              </SummaryItemPrice>
            </SummaryItem>

            {/* <Button onClick={() => initiatePayment(totalPriceWDelivery)}>
              CHECKOUT NOW
            </Button> */}
            <Link
              to="/payment"
              // target="_blank"
              // rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              onClick={() => {
                localStorage.setItem(
                  "price",
                  parseInt(totalPriceWDelivery) * 100
                );
                localStorage.setItem(
                  "email",
                  user != null ? user.email : ''
                  // getAuth(app).currentUser.email
                );
              }}
            >
              <CheckoutButton
                logoSrc={AppImages.pstackColor}
                text={"Pay with Paystack"}
                gateway={PaymentGateway.paystack}
              />
              <div style={{ marginBottom: ".6rem" }} />
              <CheckoutButton
                logoSrc={AppImages.flwaveColor}
                text={"Pay with Flutterwave"}
                gateway={PaymentGateway.flutterwave}
              />
              <div style={{ marginBottom: ".6rem" }} />
              <CheckoutButton
                logoSrc={AppImages.nombaBlack}
                text={"Pay with Nomba"}
                gateway={PaymentGateway.nomba}
              />
            </Link>
          </Summary>
        </Bottom>
      </Wrapper>
      {/* <Footer /> */}
    </Container>
  );
};

export default Cart;
