export class Inventory {
    constructor(description, inventoryId, collectionId, mainCat, name, images, sizes){
        this.description = description;
        this.inventoryId = inventoryId;
        this.mainCat = mainCat;
        this.name = name;
        this.collectionId = collectionId;
        this.images = images;
        this.sizes = sizes;
    }

    static fromJson(json){
        return Object.assign(new Inventory(), json);
    }
}

export class InventoryImage {
    constructor(image, imageId, inventoryId){
        this.image = image;
        this.imageId = imageId;
        this.inventoryId = inventoryId;
    }

    static fromJson(json){
        return Object.assign(new InventoryImage(), json);
    }
}

export class InventorySize {
    constructor(isLimitedEd, name, price, sizeId){
        this.sizeId = sizeId;
        this.price = price;
        this.name = name;
        this.isLimitedEd = isLimitedEd;
    }

    static fromJson(json){
        return Object.assign(new InventorySize(), json);
    }
}