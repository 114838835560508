import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store, persistor } from "./data/redux/store/reduxStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { NavbarProvider } from "./utils/navbarState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NavbarProvider>
            <App />
          </NavbarProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
);
