// ..................................................
  // Store paystack api secret key in environment,
  // this file is used for easy reference and calls
  // ..................................................
// const envVars = process.env;
// module.exports = {
//     stackSecret: envVars.REACT_APP_PAYSTACK_SECRET_KEY
// };

const envVars = process.env;

const config = {
    serverUrl: envVars.REACT_APP_SERVER_URL,
    paymentRedirectUrl: envVars.REACT_APP_PAYMENT_REDIRECT_URL,
};

export default config;