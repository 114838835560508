import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'collection';
const url = `${serverLink}/${endpoint}`

export const updateCollectionAction = createAsyncThunk(endpoint+'/update', async ({id, collection}) => {
    try {
        const response = await fetch(url+'/'+id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(collection),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const saveCollectionAction = createAsyncThunk(endpoint+'/save', async (collection) => {
    try {
        const response = await fetch(url+'/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(collection),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getOneCollectionAction = createAsyncThunk(endpoint+'/getAllInventory', async (id) => {
    try {
        const response = await fetch(`${url}/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

  export const getOneCollectionFullAction = createAsyncThunk(endpoint+'/getOneFull', async (id) => {
    try {
        const response = await fetch(`${url}/${id}/full`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

export const getAllCollectionAction = createAsyncThunk(endpoint+'/getAll', async () => {
  try {
      const response = await fetch(url+'/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getAllCollectionFullAction = createAsyncThunk(endpoint+'/getAllFull', async () => {
    try {
        const response = await fetch(url+'/get/all/full', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

export const deleteCollectionAction = createAsyncThunk(endpoint+'/delete', async (id) => {
    try {
        const response = await fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });