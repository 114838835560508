import React from "react";
import styled, { keyframes } from "styled-components";

import { mobile } from "../Responsive";
import { tablet } from "../Responsive";

import { Announcement } from "../models/announcement";

import chroma from 'chroma-js';
import { useDispatch } from "react-redux";
import { getAllAnnouncementAction } from "../data/redux/actions/announcement";


const animate = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  height: 30px;
  width: 100vw;
  background-color: rgb(${(prop) => prop.bg});
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  ${mobile({ width: "100vw", padding: "0px" })};
  ${tablet({ width: "100vw", padding: "0px" })};
`;

const Text = styled.div`
  animation: ${animate} 20s linear forwards;
`

const Announcements = () => {
  const dispatch = useDispatch();

  const [announcement, setAnnouncement] = React.useState(null);
  const [textColor, setTextColor] = React.useState(null);


  React.useEffect(() => {
    getAnnouncement();
    return () => {};
  }, []);

  const getAnnouncement = async () => {
    if (announcement != null) return;

    const res = await dispatch(getAllAnnouncementAction());
    if(res.payload.data === undefined) return;

    const mAnnouncement = Announcement.fromJson(res.payload.data);

    setAnnouncement(mAnnouncement);
    const mTextColor = chroma(mAnnouncement.backgroundColor).luminance() < 0.5 ? 'white' : 'black';
    setTextColor(mTextColor); 
  }

  return announcement !== null && announcement !== undefined ? (
    <Container
      bg={hexToRgb(announcement.backgroundColor, announcement.colorOpacity)}
    >
      <Text style={{color: textColor}}>
      {announcement.text}
      </Text>
    </Container>
  ) : <></>;
};

  // ..................................................
  // Get the announcement data set through the mobile
  // app that is saved in the database, and update the
  // web homepage with it
  // ..................................................
// async function getAnnouncement() {
//   const db = getDatabase(app);

//   let dbRef = ref(db, "/homepage/announcement");
//   const dbSnapshot = await get(dbRef);

//   if (dbSnapshot.exists) {
//     const data = dbSnapshot.val();

//     let announcement = Announcement.fromJson(data);

//     return announcement;
//   }
// }

function hexToRgb(hex, opacity) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    return r + "," + g + "," + b + "," + opacity;
  }
  return null;
}

export default Announcements;
