import React from "react";

const Social = () => {
      let socialUrl = localStorage.socialUrl;
      if (socialUrl === null || socialUrl === undefined) return;

      if(socialUrl !== undefined){
        checkForUrl();
      }

  function checkForUrl(){
    setTimeout(()=>{
      let socialUrl = localStorage.socialUrl;

      if(socialUrl !== null && socialUrl !== undefined){
        window.location.replace(socialUrl);

          localStorage.removeItem("socialUrl");
      } else {
        checkForUrl();
      }
    }, 1000);
  }

  // Render some text when redirecting
  return (
    <div style={styles.container}>
      <h3>Redirecting...</h3>
    </div>
  );
};

const styles = {
  container: {
    padding: 30,
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};

export default Social;
