import React from "react";
import Cart from "./pages/cart-page/Cart";
import Home from "./pages/Home";
import ProductItem from "./pages/product-details-page/ProductItem";
import ProductList from "./pages/ProductList";
import Payment from "./pages/Payment";
import Social from "./pages/Social";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import WishList from "./pages/WishList";

import ScrollToTop from "./utils/scrollToTop";
import SearchPage from "./utils/searchPage";
import OpenOrder from "./pages/order-page/OpenOrder";
import ClosedOrder from "./pages/order-page/ClosedOrder";
import Collections from "./pages/collections/Collections";
import CollectionHomePage from "./pages/collections/CollectionHomePage";
import AccountOverview from "./pages/profile/AccountOverview";
import AddressBook from "./pages/profile/AddressBook";

import NavBar from "./components/nav-bar/Navbar";
import Footer from "./components/Footer";
import Announcements from "./components/Announcements";
import Newsletter from "./components/Newsletter";

const App = () => {
  const location = useLocation();
  const hideNavbarAndFooter =
    location.pathname.includes("search") ||
    location.pathname.includes("payment");

  return (
    <div>
      {!hideNavbarAndFooter && <Announcements />}
      {!hideNavbarAndFooter && <NavBar />}
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/product/:id" element={<ProductItem />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/social" element={<Social />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/order/open" element={<OpenOrder />} />
          <Route path="/order/closed" element={<ClosedOrder />} />
          <Route path="/collections" element={<CollectionHomePage />} />
          <Route path="/collection/:id" element={<Collections />} />
          <Route path="/profile" element={<AccountOverview />} />
          <Route path="/addressbook" element={<AddressBook />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ScrollToTop>
      {!hideNavbarAndFooter && <Newsletter />}
      {!hideNavbarAndFooter && <Footer />}
    </div>
  );
};

export default App;
