import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'payments';
const url = `${serverLink}/${endpoint}`

export const getFlutterwavePaymentUrlAction = createAsyncThunk('flutterwave/pay', async ({email, price, redirectUrl}) => {

    try {
        const response = await fetch(url+'/flutterwave/pay', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, price: price, redirectUrl: redirectUrl}),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const verifyFlutterwavePaymentAction = createAsyncThunk('flutterwave/verify', async ({reference, userUid}) => {
  let link = `${url}/flutterwave/verify/${reference}`;

  if(userUid !== undefined && userUid != null){
    link = `${link}/${userUid}`;
  }

    try {
        const response = await fetch(link, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getPaystackPaymentUrlAction = createAsyncThunk('paystack/pay', async ({email, price, redirectUrl}) => {

    try {
        const response = await fetch(url+'/paystack/pay', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, price: price, redirectUrl: redirectUrl}),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const verifyPaystackPaymentAction = createAsyncThunk('paystack/verify', async ({reference, userUid}) => {
  let link = `${url}/paystack/verify/${reference}`;

  if(userUid !== undefined && userUid != null){
    link = `${link}/${userUid}`;
  }

  try {
        const response = await fetch(link, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getNombaPaymentUrlAction = createAsyncThunk('nomba/pay', async ({email, price, redirectUrl}) => {

    try {
        const response = await fetch(url+'/nomba/pay', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, price: price, redirectUrl: redirectUrl}),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const verifyNombaPaymentAction = createAsyncThunk('nomba/verify', async ({reference, userUid}) => {
  let link = `${url}/nomba/verify/${reference}`;

  if(userUid !== undefined && userUid != null){
    link = `${link}/${userUid}`;
  }

  try {
        const response = await fetch(link, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});