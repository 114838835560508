export class Address {
    constructor({fullName, userUid, contact, streetAddress, id, state, city, isDefault}){
        this.fullName = fullName;
        this.isDefault = isDefault;
        this.streetAddress = streetAddress;
        this.contact = contact;
        this.userUid = userUid;
        this.id = id;
        this.city = city;
        this.state = state;
    }

    static fromJson(json) {
        return Object.assign(new Address(), json);
    }

    toJson(){
        return {
            fullName: this.fullName,
            isDefault: this.isDefault,
            streetAddress: this.streetAddress,
            contact: this.contact,
            id: this.id,
            userUid: this.userUid,
            city: this.city,
            state: this.state,
        }
    }
}