import React from 'react';
import styled, { keyframes } from 'styled-components';
import CollectionItem from './CollectionItem';

import { tablet } from "../../Responsive";
import ProductSkeleton from '../../utils/productSkeleton';


const Container = styled.div`
  width: 100vw;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const CollectionList = ({listOfInventories}) => {  
  return (
        <Container>
            { listOfInventories !== null && listOfInventories.length > 0 ?
            listOfInventories.map((item) => (
            <CollectionItem item={item} key={item.inventoryId} />
        )) : (
          <Container>
            <ProductSkeleton/>
            <ProductSkeleton/>
    </Container>
        )}
        </Container>
  )
}

export default CollectionList;