import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { mobile, tablet } from "../Responsive";

const fadeIn = keyframes`
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-image: url(${(props) => props.imageLink});
  background-size: cover;
  background-position: center;
  animation: ${(props) => props.animation} 2s ease-in-out;
  transition: opacity 1s ease-in-out;
  background-color: rgb(${(props) => props.bg});
  ${mobile({ height: "40vh", width: "50vw" })};
  ${tablet({ height: "80vh", width: "100vw" })};
`;

const BlurredOverlay = styled.div`
  width: 100vw;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgContainer = styled.div`
  height: 100vh;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`;

const SliderImage = styled.img`
  height: 100%;
  width: 100%;
  ${mobile({ height: "100vh", width: "100vw" })};
`;

const mob = () => css`
  @media only screen and (max-width: 600px) {
    width: 100vw;
    position: absolute;
    bottom: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5%;
    padding: 0;
    flex-flow: column nowrap;
    display: flex;
  }
`;

const tabContainer = () => css`
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 100vh;
    display: flex;
    margin-top: 20%;
    align-items: center;
    flex-flow: column nowrap;
  }
`;

const InfoContainer = styled.div`
  margin-right: 5%;
  margin-top: 10%;
  grid-column: 3 / 4;
  grid-row: 2 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mob};
  ${tabContainer};
`;

const GridContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;

  ${mobile({ height: "100vh", width: "100vw" })};
`;

const Title = styled.h1`
  font-size: 70px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  ${mobile({
    textAlign: "center",
    backgroundColor: "transparent",
    fontSize: "40px",
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
  })};
  ${tablet({ fontSize: "30px", marginTop: "30px" })};
`;

const Description = styled.p`
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3px;
  border-radius: 20px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  ${mobile({
    textAlign: "center",
    backgroundColor: "transparent",
    fontSize: "15px",
  })};
`;

const SliderComponent = ({ listOfCollections }) => {
  const navigate = useNavigate();

  const [fadeClass, setFadeClass] = useState(fadeIn);
  const [currentImage, setCurrentImage] = useState(
    listOfCollections[0].images[0]
  );
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const intervalTime = 5000;

  const descriptMaxLength = 60;

  useEffect(() => {
    const timer = setInterval(() => {
      const newProductIndex =
        (currentProductIndex + 1) % listOfCollections.length;
      setCurrentProductIndex(newProductIndex);
      loadImage(newProductIndex);
    }, intervalTime);

    return () => clearInterval(timer);
  }, [currentProductIndex, listOfCollections]);

  const loadImage = (productIndex) => {
    const product = listOfCollections[productIndex];
    if (product && product.images && product.images.length > 0) {
      const randomImageIndex = Math.floor(
        Math.random() * product.images.length
      );
      setFadeClass(fadeOut);

      setTimeout(() => {
        setFadeClass(fadeIn);
        setCurrentImage(product.images[randomImageIndex]);
      }, 1000);
    }
  };

  return (
    <Slide
      onClick={() =>
        navigate(
          `/collection/${listOfCollections[currentProductIndex].collectionId}`,
          {
            state: {
              collectionName: listOfCollections[currentProductIndex].title,
              collectionDescript:
                listOfCollections[currentProductIndex].description,
            },
          }
        )
      }
      animation={fadeClass}
      imageLink={currentImage || ""}
      key={currentProductIndex}
    >
      <BlurredOverlay>
        <GridContainer>
          <ImgContainer>
            <SliderImage src={currentImage || ""} />
          </ImgContainer>
          <InfoContainer>
            <Title style={{ color: "white" }}>
              {listOfCollections[currentProductIndex].title}
            </Title>
            <Description
              style={{
                color: "white",
                display: listOfCollections[currentProductIndex].description
                  ? "inline-block"
                  : "none",
              }}
            >
              {listOfCollections[currentProductIndex].description.length >
              descriptMaxLength
                ? `${listOfCollections[currentProductIndex].description.slice(
                    0,
                    descriptMaxLength
                  )}...`
                : listOfCollections[currentProductIndex].description}
            </Description>
          </InfoContainer>
        </GridContainer>
      </BlurredOverlay>
    </Slide>
  );
};

export default SliderComponent;
