export default class MyFavorite {
    constructor(description, inventoryId, mainCat, name, userUid, image, size, price){
        this.description = description;
        this.inventoryId = inventoryId;
        this.mainCat = mainCat;
        this.name = name;
        this.image = image;
        this.size = size;
        this.price = price;
        this.userUid = userUid;
    }

    static fromJson(json){
        return Object.assign(new MyFavorite(), json);
    }

    toJson() {
        return {
          description: this.description,
          inventoryId: this.inventoryId,
          name: this.name,
          mainCat: this.mainCat,
          image: this.image,
          size: this.size,
          price: this.price,
          userUid: this.userUid,
        };
      }
}