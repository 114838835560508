export const AppStatus = {
    loading: 'loading',
    success: 'succeeded',
    failed: 'failed',
    idle: 'idle'
}

export const PaymentGateway = {
    paystack: 'paystack',
    flutterwave: 'flutterwave',
    nomba: 'nomba',
}