import React from "react";
import styled from "styled-components";
import ProductItem from "../components/ProductItem";

import { Inventory, InventoryImage } from "../models/inventory";
import Announcements from "../components/Announcements";
import NavBar from "../components/nav-bar/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import ProductSkeleton from "./productSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductAction, getAllProductFullAction } from "../data/redux/actions/productAction";

const Container = styled.div`
  width: 100vw;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Title = styled.h1`
  margin: 20px;
`;

const SearchPage = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const [listOfInventories, setInventories] = React.useState(null);
  const [filteredData, setFilteredData] = React.useState(null);

  const [isWatchingForQuery, setStartedWatching] = React.useState(null);
  const [tempQuery, setTempQuery] = React.useState("");

  const mainQuery = React.useRef(null);

  React.useEffect(() => {
    getInventories();

    return () => {};
  }, []);

  const getInventories = async () => {
    if (id == null || listOfInventories !== null) {
      return;
    }

    const res = await dispatch(getAllProductFullAction());
    const products = res.payload.data;

    const inventories = [];

    for(let product of products){
      const inventory = product.product;
      inventory.sizes = product.sizes;
      inventory.images = product.images;

      inventories.push(inventory);
    }

    setInventories(inventories);

    setFilteredData(inventories);

    let searchText = localStorage.searchText;

    if (
      (searchText === null || searchText === undefined) &&
      isWatchingForQuery === null
    ) {
      setStartedWatching(true);
    }
  };

  function filterInventory(query, inventoryList) {
    if (query === " ") {
      setFilteredData(inventoryList);
    } else {
      setFilteredData(
        inventoryList.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }


  const handleSearchFieldSubmit = (e) => {
    e.preventDefault();

    filterInventory(mainQuery.current.value, [...listOfInventories])
  };

  return (
    <div>
      <Announcements />
      <NavBar handleSearchFieldSubmit={handleSearchFieldSubmit} query={mainQuery} />
      <Title>
        {" "}
        {tempQuery !== null && tempQuery !== " "
          ? "Result for '" + tempQuery + "'"
          : "Search for a fit..."}{" "}
      </Title>
      {filteredData !== null && filteredData !== undefined ? (
        <Container>
          {filteredData.map((item) => (
            <ProductItem item={item} key={item.inventoryId} />
          ))}
        </Container>
      ) : (
        <Container>
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
        </Container>
      )}
      <Newsletter />
      <Footer />
    </div>
  );
};

export default SearchPage;