import { Add, Remove, Favorite, FavoriteBorder } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import Announcements from "../../components/Announcements";
import Footer from "../../components/Footer";
import Navbar from "../../components/nav-bar/Navbar";
import Newsletter from "../../components/Newsletter";
import { mobile } from "../../Responsive";

import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";

import { useLocation, useParams } from "react-router-dom";

import {
  Inventory,
  InventoryImage,
  InventorySize,
} from "../../models/inventory";
import Order from "../../models/order";
import MyFavorite from "../../models/favorite";
import { addCommasToNumber } from "../../utils/addCommaToNumber";
import CustomSnackbar from "../../utils/displaySnackbar";
import { useDispatch, useSelector } from "react-redux";
import { getOneProductFullAction } from "../../data/redux/actions/productAction";
import {
  deleteWishlistAction,
  getOneWishlistAction,
  saveWishlistAction,
} from "../../data/redux/actions/wishlistAction";
import { saveOrderAction } from "../../data/redux/actions/orderAction";
import { useNavbar } from "../../utils/navbarState";
import { Helmet } from "react-helmet";

const Container = styled.div``;

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection: "column" })};
`;

const ImgContainer = styled.div`
  flex: 0.5;
`;

const Image = styled.img`
  height: 70vh;
  width: 450px;
  object-fit: contain;
  ${mobile({ height: "40vh" })};
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })};
`;

const Title = styled.h1`
  font-weight: 200;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 40px;
`;

const FilterContainer = styled.div`
  width: 50%;
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  ${mobile({ width: "100%" })};
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
`;

const FilterSize = styled.select`
  margin-left: 10px;
  padding: 10px;
`;

const FilterSizeOption = styled.option``;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  ${mobile({ width: "100%" })};
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;

const Button = styled.button`
  padding: 10px;
  border: 2px solid black;
  margin-left: 30px;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    background-color: #f8f4f4;
  }
`;

const ProductItem = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.authStore);
  const { isFavoriteExist } = useNavbar();

  const { id } = useParams();
  const navbarState = useNavbar();

  const [inventory, setInventory] = useState(new Inventory());
  const [selectedSize, setIVSize] = useState(new InventorySize());

  const [itemQty, setQty] = useState(1);

  const [tempSizePrice, setTempPrice] = useState(0);
  const [actualSizePrice, setActualPrice] = useState(0);

  const [isFavorite, setIsFavorite] = useState(null);

  const [isDisplaySnackBar, setOpenSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");

  React.useEffect(() => {
    getInventory();

    return () => {};
  }, []);

  const getInventory = async () => {
    if (inventory.name !== undefined) return;

    const res = await dispatch(getOneProductFullAction(id));

    const data = res.payload.data;

    if (!data) {
      displaySnackbar("An error occured, please refresh the page");
      return;
    }

    const product = data.product !== undefined ? data.product : {};
    const sizes = data.sizes !== undefined ? data.sizes : [];
    const images = data.images !== undefined ? data.images : [];

    const mInventory = Inventory.fromJson(product);
    mInventory.sizes = sizes.map((size) => InventorySize.fromJson(size));
    mInventory.images = images.map((image) => InventoryImage.fromJson(image));

    setInventory(mInventory);

    if (mInventory.sizes.length > 0) {
      setIVSize(mInventory.sizes[0]);

      setTempPrice(mInventory.sizes[0].price);
      setActualPrice(mInventory.sizes[0].price);
    }

    checkIfItemIsFavorite();
  };

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);
  };

  const checkIfItemIsFavorite = async () => {
    const userUid = selector.id;
    if (userUid == null) return;

    const res = await dispatch(
      getOneWishlistAction({ inventoryId: id, userUid: userUid })
    );

    if (res.payload.success) {
      setIsFavorite(true);
    }
  };

  const addToWishlist = async () => {
    const userUid = selector.id;

    if (userUid == null) {
      displaySnackbar("Please sign in to add item to your wishlist");
      return;
    }

    const res = await dispatch(
      saveWishlistAction({ inventoryId: id, userUid: userUid })
    );

    const success = res.payload.success;

    if (!success) {
      displaySnackbar(res.payload.message);
      return;
    }

    navbarState.addToWishlist(new MyFavorite("", id, "", "", userUid));
    setIsFavorite(true);
    displaySnackbar("Added item to your wishlist");
  };

  const removeFromWishlist = async () => {
    const userUid = selector.id;

    if (userUid == null) {
      displaySnackbar("Please sign in to remove item from your wishlist");
      return;
    }

    const res = await dispatch(
      deleteWishlistAction({ inventoryId: id, userUid: userUid })
    );

    const success = res.payload.success;

    if (!success) {
      displaySnackbar(res.payload.message);
      return;
    }

    navbarState.removeFromWishlist(id);

    setIsFavorite(false);
    displaySnackbar("Removed item from your wishlist");
  };

  const addToCart = async () => {
    const userUid = selector.id;
    const user = selector.user;

    if (userUid == null) {
      displaySnackbar("Please sign in to add item to your cart");
      return;
    }

    if (inventory.name === undefined) {
      displaySnackbar("An error occured, please reload the page and try again");
      return;
    }

    const order = new Order({
      customerEmail: user.email,
      customerName:
        user.displayName !== null && user.displayName !== undefined
          ? user.displayName
          : user.email,
      inventoryId: inventory.inventoryId,
      inventoryImage: inventory.images[0].image,
      isLimitedEd: selectedSize.isLimitedEd,
      pricePerOne: selectedSize.price,
      productName: inventory.name,
      quantity: itemQty,
      size: selectedSize.name,
      status: "pending",
      userUid: userUid,
      paymentReferenceNumber: "",
    });

    const res = await dispatch(saveOrderAction(order.toJson()));

    const success = res.payload.success;

    if (!success) {
      displaySnackbar(res.payload.messge);
      return;
    }

    navbarState.addToCart(order);
    navbarState.addToOpenOrders(order);
    displaySnackbar("Added item to cart");
  };

  const handleSizeChange = (e) => {
    let foundSize = inventory.sizes.find(
      (size) => size.name === e.target.value
    );

    setIVSize(foundSize);

    setTempPrice(foundSize.price);
    setActualPrice(foundSize.price);

    setQty(1);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
  };

  return (
    <Container>
      <Helmet>
        <meta property="og:title" content={inventory.name} />
        <meta
          property="og:image"
          content={
            inventory.images !== undefined && inventory.images.length > 0
              ? inventory.images[0]
              : ''
          }
        />
        <meta property="og:description" content={`NGN ${actualSizePrice}`} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Wrapper>
        <ImgContainer>
          <Slider {...settings}>
            {inventory.images !== undefined &&
              inventory.images.length > 0 &&
              inventory.images.map((image) => {
                return (
                  <Image
                    key={image.imageId}
                    src={image.image !== undefined ? image.image : undefined}
                  />
                );
              })}
          </Slider>
        </ImgContainer>
        <InfoContainer>
          <Title> {inventory.name} </Title>
          <Desc> {inventory.description} </Desc>
          <Price> NGN {addCommasToNumber(actualSizePrice)} </Price>
          <FilterContainer>
            <Filter>
              <FilterTitle> Size </FilterTitle>
              <FilterSize onChange={handleSizeChange}>
                {inventory.sizes !== undefined ? (
                  inventory.sizes.map((size) => (
                    <FilterSizeOption key={size.sizeId}>
                      {" "}
                      {size.name}{" "}
                    </FilterSizeOption>
                  ))
                ) : (
                  <FilterSizeOption> </FilterSizeOption>
                )}
              </FilterSize>
            </Filter>
          </FilterContainer>
          <AddContainer>
            <AmountContainer>
              <Remove
                onClick={() => {
                  if (itemQty > 1) {
                    setQty((value) => value - 1);

                    setActualPrice(actualSizePrice - tempSizePrice);
                  }
                }}
              />
              <Amount> {itemQty} </Amount>
              <Add
                onClick={() => {
                  setQty((value) => value + 1);

                  setActualPrice(
                    parseInt(actualSizePrice) + parseInt(tempSizePrice)
                  );
                }}
              />
            </AmountContainer>

            {isFavorite && isFavoriteExist(inventory.inventoryId) ? (
              <Favorite
                onClick={() => removeFromWishlist(inventory.inventoryId)}
                style={{ color: "red", fontSize: "40px" }}
              />
            ) : (
              <FavoriteBorder
                onClick={() => addToWishlist(inventory)}
                style={{ color: "red", fontSize: "40px" }}
              />
            )}

            <Button
              onClick={() => {
                addToCart(inventory, selectedSize, itemQty);
              }}
            >
              {" "}
              ADD TO CART{" "}
            </Button>

            {/* <Button onClick={() => {addToWishlist(inventory)}}> ADD TO WISHLIST </Button> */}
          </AddContainer>
        </InfoContainer>
      </Wrapper>

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage} />
    </Container>
  );
};

export default ProductItem;
