import { Send } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import {mobile} from "../Responsive";
import { saveEmailToDB } from '../utils/saveEmailsToDB';


const Container = styled.div`
height: 50vh;
background-color: black;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

const Title = styled.div`
font-size: 50px;
margin-bottom: 20px;
color: white;
`;

const Description = styled.div`
font-size: 24px;
font-weight: 300;
margin-bottom: 20px;
color: white;
${mobile({ textAlign: "center"})};
`;

const InputContainer = styled.div`
width: 50%;
height: 40px;
display: flex;
justify-content: space-between;
border-bottom: 1px solid white;
${mobile({ width: "80%"})};
`;

const Input = styled.input`

border: none;
flex: 8;
background-color: black;
padding-left: 20px;
color: white;
outline: none;
`;

const Button = styled.button`
flex: 1;
border: none;
background-color: white;
color: black;
cursor: pointer;
`;


const Newsletter = () => {
  const emailRef = React.useRef(null);

  return (
    <Container>
        <Title> Newsletter</Title>
        <Description> Get timely updates from your favorite products.</Description>
        <InputContainer>
            <Input ref={emailRef} id="email" type="text" placeholder="your email"/>
            <Button onClick={()=>{
                if(emailRef.current.value === '') return;

                saveEmailToDB(emailRef.current.value, true);

                emailRef.current.value = '';
            }}>
                <Send />
            </Button>
        </InputContainer>
    </Container>
  )
}

export default Newsletter