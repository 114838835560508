import React from "react";
import styled from "styled-components";
import ProductItem from "./ProductItem";

import { Inventory, InventoryImage } from "../models/inventory";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductAction,
  getAllProductFullAction,
} from "../data/redux/actions/productAction";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Products = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const [listOfInventories, setInventories] = React.useState(null);

  React.useEffect(() => {
    getInventories();

    return () => {};
  }, []);

  const getInventories = async () => {
    if (id == null || listOfInventories !== null) {
      return;
    }

    const res = await dispatch(getAllProductFullAction());
    const products = res.payload.data;

    const inventories = [];

    for (let product of products) {
      const inventory = product.product;
      inventory.sizes = product.sizes;
      inventory.images = product.images;

      inventories.push(inventory);
    }

    setInventories(inventories);
  };

  return listOfInventories !== null && listOfInventories !== undefined ? (
    <Container>
      {listOfInventories.map((item) => (
        <ProductItem item={item} key={item.inventoryId} />
      ))}
    </Container>
  ) : (
    <></>
  );
};

export default Products;
