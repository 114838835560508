import { nanoid } from "nanoid";

export function saveEmailToDB(email, isFromNewsletter){
  const newEmail = email.replace(/[^a-zA-Z0-9]+/g, '');

    // const auth = getAuth(app);

    // const db = getDatabase();

    // const id = auth.currentUser ? auth.currentUser.uid : nanoid(10);

    // set(
    //     ref(
    //       db,
    //       "emails/" + newEmail + ''
    //     ),
    //     {
    //         'email' : email,
    //         'id' : id,
    //     }
    //   )
    //     .catch((e) => {
    //       console.log(e);
    //     })
    //     .then((_) => {
      

    //         isFromNewsletter && alert("Thank you for subscribing, updates coming up 😁");
    //     });
}