import React from 'react'
import Announcements from '../components/Announcements'
import ScrollToTopButton from '../components/ScrollToTopButton'
import Newsletter from '../components/Newsletter'
import Slider from '../components/Slider'
import CollectionButton from '../components/CollectionButton'

const Home = () => {
  return (
    <div>
        {/* <Announcements/> */}
        {/* <Navbar /> */}
        <Slider />
        {/* <Categories /> */}
        {/* <Products /> */}
        <CollectionButton />
        {/* <Newsletter /> */}
        {/* <Footer /> */}
        <ScrollToTopButton />
    </div>
  )
}

export default Home;