import {
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

import { useNavigate, Link } from "react-router-dom";

import Favorite from "../../models/favorite";
import { SnackBar } from "../../utils/displaySnackbar";

const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Container = styled.div`
  flex: 1;
  margin: 5px;
  padding: 5px;
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover img,
  &:hover div {
    transform: scale(1.1);
  }
`;

const Image = styled.img`
  height: 60%;
  object-fit: cover;
  width: 300px;
  border-radius: 10px;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;

  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: absolute;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
`;

const ProductText = styled.h5`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  transition: transform 0.3s ease-in-out;
`;

const ProductPrice = styled.div`
  display: flex;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  gap: 0.5rem;
  transition: transform 0.3s ease-in-out;
`;

const OldPrice = styled.div`
  text-decoration: line-through;
  color: red;
`;

const ProductColor = styled.div``;

const ProductSize = styled.div`
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
`;

const CollectionHomeItem = ({ item }) => {
  let navigate = useNavigate();

  const descriptMaxLength = 40;

  return (
    <Container
      onClick={() =>
        navigate("/collection/" + item.collectionId, {
          state: {
            collectionName: item.title,
            collectionDescript: item.description,
          },
        })
      }
    >
      <Image src={item.backgroundImage} />
      <ProductTitle>
        <ProductText>{item.title}</ProductText>
        <ProductPrice>
          {" "}
          {item.description.length > descriptMaxLength
            ? `${item.description.slice(0, descriptMaxLength)}...`
            : item.description}{" "}
        </ProductPrice>
      </ProductTitle>
    </Container>
  );
};

export default CollectionHomeItem;
