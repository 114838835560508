import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import React from 'react'
import styled from 'styled-components';

import { Link, useNavigate } from 'react-router-dom';
import { addCommasToNumber } from '../../utils/addCommaToNumber';

import Favorite from "../../models/favorite";
import { useDispatch, useSelector } from 'react-redux';
import { saveWishlistAction } from '../../data/redux/actions/wishlistAction';
import CustomSnackbar from '../../utils/displaySnackbar';
import { saveOrderAction } from '../../data/redux/actions/orderAction';
import Order from '../../models/order';
import { useNavbar } from '../../utils/navbarState';
import MyFavorite from '../../models/favorite';



const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position:absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Container = styled.div`
  flex: 1;
  margin: 5px;
  min-width: 280px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover ${Info}{
    opacity: 1;
  }
`;


const Image = styled.img`
  height: 60%;
  z-index: 2;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;

  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: absolute;
  bottom: 0;
  
  
`;

const ProductText = styled.h5`
  text-align: center;
  font-size: 17px;
  width: 200px;
`;

const ProductPrice = styled.div`
  display: flex;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  gap: 0.5rem;
`;

const OldPrice = styled.div`
  text-decoration: line-through;
  color: red;
`;

const ProductColor = styled.div`

`;

const ProductSize = styled.div`
  font-size: 14px;
  text-align: center;
  color: rgba(0,0,0,0.6);
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
`;

const CollectionItem = ({item}) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const { wishlist, addToWishlist } = useNavbar();

  const navigate = useNavigate();

  const [isDisplaySnackBar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);
  };

  const mAddToWishlist = async () => {
    if(id == null) {
      displaySnackbar("Please sign in to add item to your wishlist");
      return;
    }

    const res = await dispatch(saveWishlistAction({inventoryId: item.inventoryId, userUid: id,}));

    const message = res.payload.message;

    if(!message) return;

    if(res.payload.success){
      addToWishlist(new MyFavorite("", item.inventoryId, "", "", id));
      displaySnackbar("Added successfully");
      return;
    }

    displaySnackbar(message);
  }

  if (item.images.length > 0) {
  return (
    <Container onClick={() => navigate('/product/'+item.inventoryId)}>
        <Image src={item.images[0].image} />
        <Info>
          <Icon>
              <ShoppingCartOutlined />
          </Icon>
          <Icon>
              <FavoriteBorderOutlined onClick={(e) => {
                e.stopPropagation(); // prevent the click event from container to effect
              mAddToWishlist(item);
            }} />
          </Icon>
        </Info>
        <ProductTitle>
            <ProductText>
                {item.name}
            </ProductText>
            <ProductPrice> NGN {addCommasToNumber(item.sizes[0].price)} <OldPrice>  </OldPrice> </ProductPrice>
            <ProductSize> {item.sizes[0].name} </ProductSize>
        </ProductTitle>

      <CustomSnackbar open={isDisplaySnackBar} message={snackbarMessage} />
    </Container>
  )
  }
}

export default CollectionItem