import { tablet } from "../Responsive";
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
0% {
  background-position: -50rem 0;
  background-color: hsl(200, 20%, 70%);
}
100% {
  background-position: 50rem 0;
  background-color: hsl(200, 20%, 95%);
}
`;

const SkeletonLoader = styled.div`
flex: 2;
display: flex;

& > *:not(:last-child) {
  margin-right: 2rem;
}

.skeleton-image {
  width: 200px;
  padding-top: 10px;
  background-color: #dcdcdc;
  animation: ${shimmer} 1s linear infinite alternate;
  ${tablet({ marginLeft: "10%"})};
}

.skeleton-text {
  height: 150px;
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
flex-direction: column;

  p {
    width: 15rem;
    height: 1rem;
    background-color: #dcdcdc;
    animation: ${shimmer} 1s linear infinite alternate;
  ${tablet({ width: "5rem", marginRight: '10px'})};
  }
}
`;


const OrderSkeleton = () => {
    return (
        <SkeletonLoader>
        <div className="skeleton-image" />
        <div className="skeleton-text">
          <p />
          <p />
          <p />
          <p />
        </div>
      </SkeletonLoader>
    );
}

export default OrderSkeleton;