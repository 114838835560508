import { Snackbar } from "@mui/material";
import React from "react";
import { ScreenSizes } from "./screen_sizes";

import Slide from '@mui/material/Slide';


const CustomSnackbar = ({ message, open }) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={5000}
      open={open}
      message={message}
      TransitionComponent={Slide}
      sx={{
        [ScreenSizes.mobile]: {
          width: "90%",
        },
        [ScreenSizes.tablet]: {
          width: "80%",
        },
        [ScreenSizes.desktop]: {
          width: "70%",
        },
      }}
    />
  );
};

export default CustomSnackbar;

export const displaySnackbar = (message, {setSnackbarMessage, setOpenSnackbar}) => {
  setSnackbarMessage(message);

  setOpenSnackbar(true);
  setTimeout(() => {
    setOpenSnackbar(false);
  }, 5000);
};