import React from 'react';
import { useEffect, useState } from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import styled from 'styled-components';

const Button = styled.div`
position: fixed;
bottom: 50px;
right: 50px;
width: 30px;
font-size: 20px;
border-radius: 50px;
text-align: center;
padding: 5px;
border: 2px solid black;
background-color: white;
&:hover {
    background-color: #f8f4f4;
  }
`;






const ScrollToTopButton = () => {
    const [ backToTopButton, setBackToTopButton ] = useState(false);

        useEffect(() => {
            window.addEventListener("scroll", () =>  {
                if(window.scrollY > 100) {
                    setBackToTopButton(true)
                } else {
                    setBackToTopButton(false)

                }
            })
        }, [])

        const scrollUp = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
  return (
    <div className='App'>
        {backToTopButton && (
            <Button onClick={scrollUp}> <KeyboardArrowUp /> </Button>
        )}
    </div>
  )
}

export default ScrollToTopButton