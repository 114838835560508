import {  createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../utils/config';

const serverLink = config.serverUrl;
const endpoint = 'product';
const url = `${serverLink}/${endpoint}`

export const updateProductAction = createAsyncThunk(endpoint+'/update', async ({id, product}) => {
    try {
        const response = await fetch(url+'/'+id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const saveProductAction = createAsyncThunk(endpoint+'/save', async (product) => {
    try {
        const response = await fetch(url+'/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
});

export const getOneProductAction = createAsyncThunk(endpoint+'/get', async (id) => {
  try {
      const response = await fetch(`${url}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getOneProductFullAction = createAsyncThunk(endpoint+'/getFull', async (id) => {
    try {
        const response = await fetch(`${url}/${id}/full`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

export const getAllProductAction = createAsyncThunk(endpoint+'/getAll', async () => {
  try {
      const response = await fetch(url+'/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

    return await response.json();
  } catch (error) {
    return error.message;
  }
});

export const getAllProductFullAction = createAsyncThunk(endpoint+'/getAllFull', async () => {
    try {
        const response = await fetch(url+'/get/all/full', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });

export const deleteProductAction = createAsyncThunk(endpoint+'/delete', async (id) => {
    try {
        const response = await fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
      return await response.json();
    } catch (error) {
      return error.message;
    }
  });