import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import NavBar from "../../components/nav-bar/Navbar";
import { mobile } from "../../Responsive";
import { tablet } from "../../Responsive";
import { Delete, InfoOutlined, ModeEdit } from "@mui/icons-material";
import Newsletter from "../../components/Newsletter";
import AddressModal from "./AddressModal";

import { Address } from "../../models/address";
import AccountSkeleton from "../../utils/accountSkeleton";
import CustomSnackbar, { displaySnackbar } from "../../utils/displaySnackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAddressAction,
  getAllAddressAction,
  updateUserAddressAction,
} from "../../data/redux/actions/userAddressAction";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  min-height: 70vh;
  ${mobile({ padding: "2px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  margin-top: 30px;
  text-align: center;
`;

const Details = styled.div`
  padding: 1rem;
  margin: 20px 20px;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  ${mobile({ padding: ".5rem", margin: "10px 10px" })};
`;

const AddressDetails = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${mobile({ paddingTop: "10px", gap: "0.2rem" })};
`;

const Name = styled.span`
  font-size: 25px;
  ${mobile({ fontSize: "20px" })};
`;

const AddressInfo = styled.span`
  color: gray;
  width: 300px;
  ${mobile({ fontSize: "13px" })};
`;

const Number = styled.span`
  font-size: 15px;
  ${mobile({ fontSize: "13px" })};
`;

const Default = styled.span`
  display: flex;
  color: green;
  font-size: 15px;
  gap: 0.4rem;
`;

const Text = styled.span`
  padding-top: 2px;
`;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const MakeDefault = styled.h2`
  color: #7f7f7f;
  font-size: 15px;
  cursor: pointer;
  ${mobile({ fontSize: "15px" })};

  &:hover {
    color: lightgray;
  }
`;

const ProductAddToCartContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
padding: 10px;
background-color: white;
border: none;
border-radius: 10px;
cursor: pointer;
margin 0px 10px;
font-weight: 500;
&:hover{
    color: #404040;
}
`;

const AddressContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const AddressButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Button2 = styled.button`
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  margin 30px 10px;
  font-weight: 500;
  &:hover{
      background-color: white;
      color: black;
      transition: 0.5s ease;
  }
`;

const AddressBook = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.authStore);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const selectedAddress = React.useRef(null);

  const [addressList, setAddress] = React.useState([]);

  const [isDisplaySnackBar, setIsDisplay] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");

  React.useEffect(() => {
    getAddresses();

    return () => {};
  }, []);

  const getAddresses = async () => {
    if (id == null || addressList === undefined || addressList.length > 0)
      return;

    const res = await dispatch(getAllAddressAction(id));

    const data = res.payload.data;

    if (!data) {
      displaySnackbar(
        "An error occured when getting address, please reload the page",
        {
          setOpenSnackbar: setIsDisplay,
          setSnackbarMessage: setSnackbarMessage,
        }
      );
      return;
    }

    // const addresses = data.map(add => Address.fromJson(add));
    setAddress(data);
  };

  const removeAddress = async (addressId) => {
    if (id == null || addressId === undefined) {
      displaySnackbar(
        "An error occured, please reload the page and try again",
        {
          setOpenSnackbar: setIsDisplay,
          setSnackbarMessage: setSnackbarMessage,
        }
      );
      return;
    }

    const res = await dispatch(
      deleteAddressAction({ id: addressId, userUid: id })
    );

    if (!res.payload.success) {
      displaySnackbar(
        "An error occured, please reload the page and try again",
        {
          setOpenSnackbar: setIsDisplay,
          setSnackbarMessage: setSnackbarMessage,
        }
      );
      return;
    }

    displaySnackbar("Removed address from list", {
      setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setSnackbarMessage,
    });

    setAddress((addresses) => addresses.filter((add) => add.id !== addressId));
  };

  const setAsDefaultAddress = async (address) => {
    if (id == null || address.id === undefined) {
      displaySnackbar(
        "An error occured, please reload the page and try again",
        {
          setOpenSnackbar: setIsDisplay,
          setSnackbarMessage: setSnackbarMessage,
        }
      );
      return;
    }

    const newAddress = address;
    newAddress.isDefault = true;

    const res = await dispatch(
      updateUserAddressAction({ id: address.id, address: newAddress })
    );

    const success = res.payload.success;

    if (!success) {
      displaySnackbar("An error occured, please try again", {
        setOpenSnackbar: setIsDisplay,
        setSnackbarMessage: setSnackbarMessage,
      });

      return;
    }

    displaySnackbar("Updated default address", {
      setOpenSnackbar: setIsDisplay,
      setSnackbarMessage: setSnackbarMessage,
    });

    const oldDefaultIndex = addressList.findIndex(add => add.isDefault == true);
    const updatedOldDefault = addressList[oldDefaultIndex];
    updatedOldDefault.isDefault = false;
    addressList[oldDefaultIndex] = updatedOldDefault;

    // Add new default address
    const newAddressList = addressList.filter((add) => add.id !== address.id);
    newAddressList.push(newAddress);


    setAddress(newAddressList);
  };

  return (
    <Container>
      {/* <NavBar /> */}
      <Wrapper>
        <Title> Addresses </Title>
        {addressList.length > 0 ? (
          addressList.map((address) => (
            <React.Fragment key={address.id}>
              <Details>
                <AddressDetails>
                  <Name> {address.fullName} </Name>
                  <AddressInfo> {address.streetAddress} </AddressInfo>
                  <AddressInfo>
                    {" "}
                    {address.city}, {address.state}{" "}
                  </AddressInfo>
                  <Number> {address.contact} </Number>

                  {address.isDefault && (
                    <Default>
                      {" "}
                      <InfoOutlined /> <Text> Default Address </Text>{" "}
                    </Default>
                  )}

                  <PriceDetail>
                    {!address.isDefault ? (
                      <MakeDefault
                        onClick={() => {
                          setAsDefaultAddress(address);
                        }}
                      >
                        {" "}
                        SET AS DEFAULT
                      </MakeDefault>
                    ) : (
                      <MakeDefault />
                    )}

                    <ProductAddToCartContainer>
                      <Button
                        onClick={() => {
                          selectedAddress.value = address;

                          setTimeout(() => toggleModal(), 1000);
                        }}
                      >
                        {" "}
                        <ModeEdit style={{ color: "black" }} />{" "}
                      </Button>
                      <Button onClick={() => removeAddress(address.id)}>
                        {" "}
                        <Delete style={{ color: "red" }} />{" "}
                      </Button>
                    </ProductAddToCartContainer>
                  </PriceDetail>
                </AddressDetails>
              </Details>
              {/* {index < addressList.length - 1 && <Hr />} */}
            </React.Fragment>
          ))
        ) : (
          <>
            <AccountSkeleton />
            <AccountSkeleton />
          </>
        )}

        <AddressContainer>
          <AddressButton>
            <Button2
              onClick={() => {
                selectedAddress.value = null;

                setTimeout(() => toggleModal(), 1000);
              }}
            >
              {" "}
              Add a new Address{" "}
            </Button2>
          </AddressButton>
        </AddressContainer>
        {modal && (
          <AddressModal
            handleClose={toggleModal}
            address={selectedAddress.value}
          />
        )}
      </Wrapper>

      <CustomSnackbar open={isDisplaySnackBar} message={snackBarMessage} />
    </Container>
  );
};

export default AddressBook;
