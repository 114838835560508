import { tablet } from "../Responsive";
import styled, { keyframes } from 'styled-components';
import { mobile } from "../Responsive";

const shimmer = keyframes`
0% {
  background-position: -50rem 0;
  background-color: hsl(200, 20%, 70%);
}
100% {
  background-position: 50rem 0;
  background-color: hsl(200, 20%, 95%);
}
`;

const SkeletonLoader = styled.div`
padding: 2rem;
  margin: 30px 20px;
  border-radius: .5rem;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .25);
  height: 100%;
  ${mobile({ padding: "1rem", margin: "20px 10px" })};

& > *:not(:last-child) {
  margin-right: 2rem;
}

.skeleton-title {
  width: 30vw;
  height: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
  background-color: #dcdcdc;
  animation: ${shimmer} 1s linear infinite alternate;
  ${mobile({ width: '50vw', height: '10px'})};
}

.skeleton-text {
  height: 100px;
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
flex-direction: column;

  p {
    width: 15rem;
    height: 1rem;
    background-color: #dcdcdc;
    animation: ${shimmer} 1s linear infinite alternate;
  ${mobile({ width: "10rem", height: '.7rem', marginRight: '10px'})};
  }
}
`;


const AccountSkeleton = () => {
    return (
        <SkeletonLoader>
        <div className="skeleton-title" />
        <div style={{borderBottom: '1px solid grey'}}/>
        <div className="skeleton-text">
          <p />
          <p />
          <p />
        </div>
      </SkeletonLoader>
    );
}

export default AccountSkeleton;