export class Announcement{
    constructor(text, backgroundColor, colorOpacity){
        this.text = text;
        this.backgroundColor = backgroundColor;
        this.colorOpacity = colorOpacity;
    }

    static fromJson(json){
        return Object.assign(new Announcement(), json);
    }
}